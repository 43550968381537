@import "/src/common/styles/colors.scss";

.flatted-input {
    // @extend .normal-16;
    border: none !important;
    // border-bottom: 1px solid colors.$gray-1 !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    outline: none !important;
    // color: colors.$gray-0 !important;

    &:disabled {
        // color: colors.$gray-7 !important;
    }

    &::placeholder {
        font-size: 16px;
        // color: colors.$gray-1 !important;
    }

    &:focus,
    &:focus-visible {
        box-shadow: none !important;
        outline: none !important;
    }
}

.flatted-dropdown-input {
    .flatted-input {
        width: 100%;
        position: relative;
    }

    .dropdown-icon {
        position: absolute;
        right: 12px;
        line-height: 30px;
        // color: colors.$gray-2;

        svg {
            height: 11px;
            width: 11px;
        }
    }
}

.flatted-label-input {
    display: flex;

    .flatted-input {
        width: 100%;
        position: relative;
        z-index: 900;
        text-align: right;
    }

    label {
        // @extend .normal-14-500;
        position: absolute;
        z-index: 901;
    }
}

.ant-input-number.flatted-input-number {
    width: 100%;
    border: none !important;

    .ant-input-number-handler-wrap {
        display: none;
    }

    .ant-input-number-input {
        // @extend .flatted-input;

        &:focus,
        &:focus-visible {
            box-shadow: none !important;
            outline: none !important;
            border: none;
        }
    }
}


.ant-form-item {
    .ant-form-item-label {
        top: 0;
        left: 0;
        z-index: 99;
        height: 18px;
        padding: 0 1px;
        font-size: 14px;
        position: absolute;
        letter-spacing: 0.15px;
        color: getColor(text, dark);
        transform: translate(8px, -50%);
        background-color: getColor(background, lightest);

        .ant-form-item-required {
            height: 14px;
            font-size: 14px;
            letter-spacing: 0.15px;
            color: getColor(text, dark);

            &:after,
            &:before {
                display: none !important;
            }
        }
    }

    .flatted-phone-input {
        .ant-input-affix-wrapper {
            padding: 0;
            width: 100%;
            height: 40px;
            border-radius: 4px;
            // text 
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.15px;
            border: 1px solid #ccc !important;
            box-shadow: none !important;

            .ant-input-prefix {
                padding: 6px 16px;
                border-right: 1px solid #ccc !important;

                .ant-select-selector {
                    padding: 0;
                    cursor: pointer;

                    .ant-select-selection-search {
                        input {
                            border: none !important;
                        }
                    }

                    .ant-select-selection-item {
                        padding: 0;
                        font-size: 18px;
                    }
                }

                // remove arrow and search icon
                .ant-select-arrow {
                    display: none;
                }
            }

            .ant-input {
                padding: 16px;
                font-size: 16px;

                &::placeholder {
                    font-size: 18px;
                    color: getColor(text, medium);
                }
            }
        }
    }
}

.text-error {
    font-size: 13px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}