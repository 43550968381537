@import "/src/common/styles/colors.scss";

.cc-page {
    width: 100%;
    height: 100% !important;

    .cc-content {
        overflow-y: auto;
        box-sizing: content-box;
        height: calc(100% - 64px);

        .cc-stepper {
            display: flex;
            margin: 0 auto;
            max-width: 1288px;
            padding: 48px 48px 0;
            flex-direction: column;
            box-sizing: content-box;

            .stepper {
                width: 100%;
                height: 6px;
                border-radius: 4px;
                margin-bottom: 20px;
                background-color: getColor(background, lightGrey);

                .stepper-line {
                    height: 6px;
                    position: relative;
                    border-radius: 4px;
                    transition: .4s ease all;
                    background-color: getColor(secondary);

                    &::after {
                        top: 50%;
                        right: 0;
                        width: 15px;
                        content: "";
                        height: 15px;
                        border-radius: 50%;
                        position: absolute;
                        transform: translateY(-50%);
                        border: 2px solid getColor(secondary);
                        background-color: getColor(background, lightest);
                    }
                }
            }

            .cc-stepper-labels {
                display: flex;
                position: relative;
                align-items: flex-start;

                .cc-stepper-label {
                    display: flex;
                    cursor: pointer;
                    font-size: 12px;
                    padding: 2px 4px;
                    line-height: 20px;
                    text-align: center;
                    position: relative;
                    border-radius: 4px;
                    align-items: center;
                    letter-spacing: 0.22px;
                    justify-content: center;
                    color: getColor(text, dark);
                    transition: all 0.2s ease-in-out;

                    &:hover {
                        color: getColor(primary);
                        transition: all 0.2s ease-in-out;
                    }

                    .check {
                        left: 50%;
                        opacity: 0;
                        top: -32px;
                        content: "";
                        width: 18px;
                        height: 18px;
                        cursor: default;
                        border-radius: 50%;
                        position: absolute;
                        transition: 0.2s ease-in all;
                        -ms-transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                        background-color: getColor(secondary);
                        transform: translate(-50%) rotate(45deg);
                        border: 2px solid getColor(text, white);

                        &.show-check {
                            opacity: 1;
                            transition: 0.2s ease-in all;
                        }

                        &::before,
                        &::after {
                            content: "";
                            position: absolute;
                            background-color: getColor(text, white);
                        }

                        &:after {
                            top: 8px;
                            left: 3px;
                            width: 6px;
                            height: 3px;
                        }

                        &:before {
                            top: 2px;
                            left: 6px;
                            width: 3px;
                            height: 9px;
                        }
                    }
                }
            }
        }

        .cc-steppers {
            padding: 48px;
            margin: 0 auto;
            max-width: 1288px;
            box-sizing: content-box;

            .card {
                .card-content {
                    color: getColor(text, dark);
                    .step-name {
                        font-size: 20px;
                        line-height: 28px;
                        margin-bottom: 24px;
                        letter-spacing: 0.15px;
                    }
                    .step-description {
                        font-size: 14px;
                        font-weight: 400;
                        margin: -12px 0 24px;
                    }
                    .step-label {
                        margin: 0;
                        font-size: 16px;
                        line-height: 28px;
                        letter-spacing: 0.44px;
                    }

                    .ant-input {
                        &.input-error {
                            border: 1px solid getColor(status, error) !important;
                        }

                        &.disabled {
                            cursor: not-allowed;
                            color: getColor(text, black)
                        }

                        &::placeholder {
                            font-size: 16px;
                            color: getColor(text, medium);
                        }

                        &.error {
                            border: 1px solid getColor(status, error) !important;
                        }
                    }

                    .text-error {
                        left: 0;
                        top: 100%;
                        position: absolute;
                    }
                }

            }

            .junction-input-label {
                position: relative;
            }

            .junction-date {
                position: relative;

                .text-error {
                    position: absolute;
                    top: 100%;
                }
            }
        }
    }
}