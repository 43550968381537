@import "/src/common/styles/colors.scss";

.junction-url {
    display: grid;
    padding-bottom: 23px;
    align-items: center;
    grid-template-columns: 2fr 0 1fr;
    grid-template-areas:
        "label  label  description"
        "input input description";

    .label-input {
        grid-area: label;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.44px;
    }

    .input-junction {
        width: 100%;
        grid-area: input;
        grid-column: span 2;
    }


    .description {
        margin: 0 0 0 20px;
        padding-left: 10px;
        grid-area: description;
        border-left: 1px solid getColor(background, grey);
    }
}

.junction-e-options {
    display: grid;
    padding-top: 0px;
    grid-template-columns: 2fr 0 1fr;

    .actions {
        grid-column: span 2;

        .integration-title {
            font-size: 20px;
            line-height: 20px;
            margin-bottom: 22px;
            letter-spacing: 0.31px;
        }

        .label-input {
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.44px;
        }

        .junction-radios {
            margin-top: 16px;
        }
    }

    .description {
        margin: 0 0 0 20px;
        padding-left: 10px;
        border-left: 1px solid getColor(background, grey);
        height: max-content;
    }

    .description:nth-of-type(2) {
        grid-column: 3;
        margin-top: 38.58px;
    }

    .section-go{

        grid-column: 1;
    }
}

.container-goals {
    .title-goals {
        font-size: 20px;
        margin-top: 32px;
        line-height: 20px;
        letter-spacing: 0.31px;
    }

    .header-container-goals {
        height: 40px;
        display: flex;
        padding: 0 4px 0 25px;
        margin-bottom: 8px;
        border-radius: 4px;
        align-items: center;
        border: 1px solid #E5E5E5;
        justify-content: space-between;
        background-color: getColor(background, titleTable);
        &.end {
            justify-content: flex-end;
        }

        .checkbox-container .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
            border: 1px solid getColor(text, dark);
        }
    }

    .checkbox-container .ant-checkbox-wrapper {
        .ant-checkbox {
            margin-right: 64px;
        }

        .ant-checkbox + span{
            align-self: center;
        }

        .checkbox-text {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: getColor(text, dark);

            .text-header {
                text-transform: uppercase;
            }
        }

    }

    .input-container{
        .input-junction {
            top: 50%;
            transform: translateY(-50%);

            .input {
                padding: 2.44px 12px;
            }
        }
    }

    .all-goals {
        .checkbox-container .ant-checkbox-wrapper {
            height: 41px;
            display: flex;
            padding: 0 25px;
            margin-bottom: 8px;
            border-radius: 4px;
            align-items: center;
            background-color: getColor(background, goal);
            border: 1px solid getColor(background, lightGrey);
        }
        .empty-goals {
            display: flex;
            font-size: 20px;
            margin-top: 40px;
            justify-content:center;
        }
    }
}