@import "/src/common/styles/colors.scss";

.junction-validity {
    display: flex;
    justify-content: space-between;

    .ant-form-item {
        &:first-child {
            width: 38%;
        }

        &:last-child {
            width: 50%;
        }
    }
}

.payment-success {
    color: #3bb481;
    font-size: 24px;
    margin-top: 20px;
}

.payment-error {
    color: #d62626;
    font-size: 24px;
    margin-top: 20px;
}

.junction-cvv {
    display: flex;
    align-items: center;
    margin-bottom: 36px;

    .ant-form-item {
        margin: 0;
        width: 60%;
    }

    .cvv-img {
        width: 40%;

        img {
            width: 55px;
        }
    }
}

.feedback-coupon {
    text-align: left;
    margin-top: -35px;
    margin-bottom: 20px;

    &.error {
        .text {
            color: getColor(status, error);
        }
    }

    p {
        margin: 0;
        margin-bottom: 25px;
    }

    .text {
        color: getColor(status, success);
    }

    .subtotal,
    .price,
    .new-price {
        margin: 0;
        display: flex;
        font-size: 14px;
        justify-content: space-between;

        span {
            font-size: 15px;
        }
    }

    .subtotal {
        padding: 12px 0 8px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .price {
        margin-bottom: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .new-price {
        padding-bottom: 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
}
