@import "/src/common/styles/colors.scss";

.inputs-step-name {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .junction-input {
    margin-bottom: 24px;
    
    &:last-child {
      margin-bottom: 0;
    }

    &.junction-name {
      width: 49%;
    }

    &.junction-description {
      width: 100%;
      display: flex;
      position: relative;
      flex-direction: column;

      textarea {
        min-height: 168px;
        outline: none;
        padding: 6px 12px;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 16px !important;
        box-shadow: none !important;
        border: 1px solid getColor(background, medium) !important;
        background-color: getColor(background, light) !important;
        &.input-error {
          border: 1px solid getColor(status, error) !important;
        }
      }
    }

    &.junction-date-coin {
      width: 490px;
      display: flex;
      justify-content: space-between;

      .junction-date {
        width: 335px;
        display: flex;
        flex-direction: column;

        .inputs-labels {
          display: flex;

          .label-input {
            width: 50%;
          }
        }
      }

      .junction-coin {
        width: 49%;
      }
    }
  }
}