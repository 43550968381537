@import "/src/common/styles/colors.scss";

.style-value {
    display: flex;
    font-size: 12px;
    cursor: default;
    margin-left: 5px;
    font-weight: normal;
    align-items: center;
    color: getColor(text, dark);

    .icon {
        font-size: 12px;
        margin: 0 3px 2px;
        &.icon-growth {
            color: getColor(status, success) !important;
        }
        &.icon-fall {
            color: getColor(status, error) !important;
        } 
    }
    &.growth {
        color: getColor(status, success) !important;
    }
    &.fall {
        color: getColor(status, error) !important;
    }
}