@import "/src/common/styles/colors.scss";

.tiktok-approve-container {
    border-radius: 4px;
    padding: 16px 14px;
    border: 1px solid getColor(text, gray3);

    .button-container {
        display: flex;
        width: 100%;
        height: 100%;
        font-size: 13px;
        font-weight: 700;
        align-items: center;
        justify-content: center;

        .ant-spin {
            height: auto;
            margin-right: 10px;
            color: white;

            svg {
                fill: white;
            }
        }
    }

    .tiktok-approve-header {
        width: 100%;
        display: flex;
        font-size: 16px;
        margin-bottom: 8px;
        align-items: center;
        color: getColor(text, dark);
        .tiktok-username {
            margin-left: 11px;
            margin-right: 8px;
        }

        .icon {
            font-size: 10px;
        }
    }

    .tiktok-approve-thumbnail-container {
        height: 392px;
        width: 247px;
        margin-left: auto;
        margin-right: auto;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .tiktok-approve-footer {
        display: flex;
        margin-top: 16px;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        justify-content: space-between;

        .reject-button .icon {
            font-size: 16px;
            font-weight: 700;
            margin-left: 8px;
        }

        .green-button .icon {
            font-size: 16px;
            font-weight: 700;
            margin-right: 8px;
        }

        .green-button,
        .reject-button {
            width: 48%;
            transition: all 0.2s ease-in-out;

            &:hover {
                opacity: .6;
            }
        }
    }
}