@import "/src/common/styles/colors.scss";

.performance-content {
    display: flex;
    padding-top: 22px;
    align-items: flex-end;

    .performance-text {
        font-size: 16px;
        line-height: 12px;
        letter-spacing: 0.44px;
        color: getColor(text, dark);
    }

    .icon-tooltip {
        margin: 0 13px 0 3px;
    }

    .performance-bar {
        cursor: default;
        position: relative;
        border-radius: 4px;
        .bar-labels {
            display: flex;
            justify-content: space-between;
            .bar-label {
                width: 33.3%;
                font-size: 12px;
                font-weight: 400;
                margin-bottom: 2px;
                text-align: center;
                color: getColor(text, dark);
            }
        }
        .bar {
            height: 11px;
            width: 190px;
            border-radius: 6px;
            background: linear-gradient(270deg, #299F6F 0% 33.32%, #FCB848 33.33% 66.65%, #F22E49 66.66%);
            .bar-pointer {
                height: 12px;
                position: relative;

                .pointer {
                    top: 100%;
                    height: 20px;
                    position: absolute;
                    border-radius: 50%;
                    transform: translateY(-50%);
                    // .tooltip-fixed {
                    //     left: 50%;
                    //     top: -33px;
                    //     height: 25px;
                    //     display: flex;
                    //     padding: 0 6px;
                    //     position: absolute;
                    //     border-radius: 4px;
                    //     align-items: center;
                    //     justify-content: center;
                    //     transform: translate(-50%);
                    //     color: getColor(text, white);
                    //     background-color: getColor(background, darkGrey);
                    //     &::before {
                    //         bottom: 0px;
                    //         content: "";
                    //         position: absolute;
                    //         transform: translateY(65%);
                    //         border-left: 5px solid transparent;
                    //         border-right: 5px solid transparent;
                    //         border-top: 9px solid getColor(background, darkGrey);
                    //     }
                    // }
                }
            }
        }
    }
}