@import "/src/common/styles/colors.scss";

.input-container {
    position: relative;
    .input-junction {
        display: flex;
        width: 260px;
        position: relative;
    
        .input {
            outline: none;
            padding: 6px 12px;
            border-radius: 4px;
            box-sizing: border-box;
            font-size: 16px !important;
            box-shadow: none !important;
            background-color: getColor(background, lightest);
            border: 1px solid getColor(background, lightGrey) !important;
    
            
            &.input-full {
                border: 1px solid getColor(background, medium) !important;
                background-color: getColor(background, light) !important;
                
                &::placeholder {
                    font-size: 16px;
                    color: getColor(text, medium);
                }
                
                .ant-input,
                input {
                    color: getColor(text, black) !important;
                    background-color: transparent;
                    background-color: transparent;
                }
                
                &.white {
                    background-color: getColor(background, lightest) !important;
                }
            }
            
            &.input-outline {
                .ant-input::placeholder {
                    font-size: 16px;
                    color: getColor(text, dark);
                }
            }
    
            &.input-error {
                border-color: getColor(status, error) !important;
            }
    
            .ant-input-prefix {
                margin-right: 8px;
                .icon {
                    font-size: 16px;
                    color: #979797;
                    padding-right: 5px;
                }
            }
        }
    
        .ant-input,
        input {
            color: getColor(text, black) !important;
            background-color: getColor(background, lightest);
        }
    
        .icon-password {
            transition: 0.2s ease-in all;
            &:hover {
                opacity: 0.6;
            }
        }
    
        .suffix {
            width: 25px;
            display: flex;
            cursor: pointer;
            margin-left: -4px;
            position: relative;
            align-items: center;
            justify-content: center;
            border-radius: 0 4px 4px 0;
            transition: 0.2s ease-in all;
            color: getColor(text, white);
            background-color: getColor(status, success);
    
            &:hover {
                opacity: 0.6;
                transition: 0.2s ease-in all;
            }
        }
    
        .input-count {
            right: 0;
            top: 100%;
            font-size: 14px;
            line-height: 20px;
            text-align: right;
            position: absolute;
            letter-spacing: 0.22px;
            color: getColor(text, dark);
        }
    }
}

.text-error {
    font-size: 12px;
    text-align: left;
    color: getColor(status, error) !important;
}