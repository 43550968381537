@import "/src/common/styles/colors.scss";

.container-mentions {
    width: 100%;
    cursor: text;
    display: flex;
    padding: 24px;
    flex-wrap: wrap;
    min-height: 129px;
    border-radius: 4px;
    background-color: getColor(background, medium);

    .ant-input {
        width: 280px;
        height: 40px;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0;
        border: none !important;
        box-shadow: none !important;
        background-color: transparent !important;
    }

    &.error-mentions {
        border: 1px solid getColor(status, error);
    }
}