@import "/src/common/styles/colors.scss";

.tag-mention {
    height: 40px;
    display: flex;
    cursor: default;
    padding: 0 16px;
    border-radius: 4px;
    align-items: center;
    margin: 0 16px 16px 0;
    color: getColor(text, dark);
    background-color: getColor(primary, .2);
    box-shadow: 0 2px 4px 0 rgba(201, 201, 201, 0.5);
    .name-mention {
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0;
    }
    
    .icon {
        cursor: pointer;
        margin-left: 9px; 
        color: getColor(text, dark);
        transition: 0.3s all ease-in-out;
        &:hover {
            transition: 0.3s all ease-in-out;
            box-shadow: 0 2px 4px 0 rgba(201, 201, 201, 0.5);
        }
    }
}