@import "/src/common/styles/colors.scss";

.campaign-table {
    .junction-header {
        color: getColor(text, dark);

        .subheader-left {
            .subheader-title {
                display: flex;
                font-size: 30px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 39px;
                overflow: hidden;
                align-items: center;
                text-transform: capitalize;

                .ant-btn {
                    margin-left: 12px;
                    &:hover {
                        background-color: transparent !important;
                    }

                    .icon-more {
                        padding: 8px;
                        border-radius: 50%;
                        font-weight: bold;
                        transition: 0.2s all ease-in-out;

                        &.icon-more-open {
                            transform: rotate(90deg);
                            background-color: getColor(background, grey);
                        }
                    }
                }
            }

            .text {
                font-size: 14px;
                line-height: 28px;
                color: #807F80;
                letter-spacing: 0.44px;
            }
        }

        .subheader-right {
            display: flex;
            align-items: center;

            .icon {
                margin-left: 8px;
            }
        }
    }

    .scroll-content {
        height: calc(100vh - 64px - 88px); // - header - subheader 

        .campaign-table-content {
            padding: 48px;
            margin: 0 auto;
            max-width: 1288px;
            overflow-x: hidden;
            box-sizing: content-box;

            .card {
                overflow: hidden;
                margin: 24px 0 56px;

                .card-loading {
                    .container-loading {
                        margin: 60px auto 50px !important;
                    }
                }

                .card-header {
                    display: flex;
                    align-items: center;
                    margin-bottom: 31px;
                    justify-content: space-between;

                    .campaign-title-data {
                        font-size: 20px;
                        line-height: 24px;
                        margin-bottom: 0;
                        letter-spacing: 0.15px;
                    }
                }

                .segment-filter-table {
                    display: flex;
                    margin: 8px 0 32px;
                    position: relative;
                    transition: .5s all ease-in-out;
                    border-bottom: 1px solid getColor(background, medium);

                    &.segment-tiktok {
                        .option-table {
                            &.active {
                                .text-option,
                                .text-value {
                                    color: getColor(socials, tiktok);
                                }
                            }
                        }
                        .segment-underline {
                            background-color: getColor(socials, tiktok);
                        }
                    }

                    .option-table {
                        width: 17%;
                        padding: 10px;
                        display: flex;
                        cursor: pointer;
                        align-items: center;
                        box-sizing: border-box;
                        justify-content: center;

                        &.active {

                            .text-option,
                            .text-value {
                                font-weight: 500;
                                color: getColor(primary);
                            }

                            &.option-e-commerce {

                                .text-option,
                                .text-value {
                                    color: getColor(tertiary);
                                }
                            }

                            &:hover {
                                opacity: 1;
                            }
                        }

                        .text-option {
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 21px;
                            letter-spacing: 0.44px;
                        }

                        &:hover {
                            opacity: 0.6;
                            transition: .2s all ease-in-out;
                        }
                    }

                    .segment-underline {
                        left: 0;
                        width: 17%;
                        height: 4px;
                        bottom: -2px;
                        position: absolute;
                        border-radius: 0 0 2px 2px;
                        transition: .2s all ease-in-out;
                        background-color: getColor(primary);

                        &.position-1 {
                            background-color: getColor(tertiary);
                        }
                    }
                }
            }
        }
    }
}