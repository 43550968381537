@import "/src/common/styles/colors.scss";

.table-contractor {
    width: 100%;
    // border-spacing: 0 7px;
    // border-collapse: separate;

    thead {
        margin-bottom: 7px;

        tr {
            height: 40px;

            .th-contractor {
                margin: 1px;
                cursor: pointer;
                font-size: 12px;
                font-weight: 500;
                text-align: start;
                text-transform: uppercase;
                color: getColor(text, dark) !important;
                background-color: getColor(background, medium);
                border-top: 1px solid getColor(background, lightGrey) !important;
                border-bottom: 1px solid getColor(background, lightGrey) !important;

                .name-column {
                    text-decoration: underline;
                }
                .icon-down {
                    font-size: 12px;
                    margin-left: 8px;
                    text-decoration: none;
                    transition: .2s all ease-in-out;

                    &.bigger {
                        transform: rotate(180deg);
                    }

                    &.smaller {
                        transform: rotate(0deg);
                    }
                }

                &:first-child {
                    text-align: left;
                    padding-left: 26px;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                    border-left: 1px solid getColor(background, lightGrey) !important;
                }

                &:last-child {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-right: 1px solid getColor(background, lightGrey) !important;
                }
                
                &.default {
                    cursor: default;
                }
            }
        }
    }

    tbody {
        tr {
            margin-bottom: 7px;
            th {
                height: 40px;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0;
                text-align: center;
                font-weight: normal;
                color: getColor(text, dark);
                background-color: getColor(background, light);
                // border-top: 1px solid getColor(background, lightGrey) !important;
                border-bottom: 1px solid getColor(background, lightGrey) !important;

                &:first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                    border-left: 1px solid getColor(background, lightGrey) !important;
                }

                &:last-child {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-right: 1px solid getColor(background, lightGrey) !important;

                }
            }

            .th-profile {
                text-align: left;
                padding-left: 26px;
                text-transform: capitalize;
                .avatar {
                    margin-right: 5px;
                }
            }
            .th-network {
                text-align: start;
                .tiktok-icon-circle {
                    width: 22px;
                    height: 22px;
                    padding: 4px;
                    border-radius: 50%;
                    background-color: getColor(socials, tiktok);
                }
            }
            .th-trash {
                padding-right: 13px;
                .icon {
                    font-size: 16px;
                    cursor: pointer;
                }
            }
            .th-connected {
                display: flex;
                cursor: default;
                align-items: center;
                justify-content: flex-start;
                &.connected {
                    cursor: pointer;
                    .icon {
                        transition: .2s all ease-in-out;

                        &:hover {
                            opacity: 0.6;
                        }
                    }
                }
                .icon {
                    font-size: 20px;
                    margin-bottom: 2px;
                    &.icon-stamp---airscore {
                        margin-bottom: 0;
                        color: getColor(secondary);
                    }
                }
                .span-share {
                    font-size: 16px;
                    line-height: 28px;
                    padding-left: 7px;
                    letter-spacing: 0.44px;
                    color: getColor(text, dark);
                }
                .logo {
                    width: 16px;
                }
            }
        }
    }
}