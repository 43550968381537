@import "/src/common/styles/colors.scss";

.tiktok-page {
    width: 100%;
    height: 100%;

    .tiktok-content {
        padding: 48px;
        margin: 0 auto;
        overflow-x: hidden;
        box-sizing: border-box;

        .card {
            margin: 0 auto;
            max-width: 1288px;

            .tiktok-card-title {
                margin: 0;
                font-size: 20px;
                font-weight: 400;
            }

            .tiktok-card-subtitle {
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                margin-bottom: 24px;
                padding-bottom: 16px;
                border-bottom: 1px solid getColor(text, medium, .2);
            }
        }
    }
}

.modal-add-influencer {
    width: 70% !important;
    max-width: 1100px !important;

    .ant-modal-header {
        display: none;
    }

    .junction-type-save {
        justify-content: flex-end;
    }
}

.modal-add-options {
    max-width: 380px;

    .ant-modal-header {
        border: none;
        font-size: 16px;
    }

    .ant-form-item {
        margin-bottom: 0;

        .option {
            margin-bottom: 16px;

            .ant-radio {
                margin-right: 8px;

                input,
                .ant-radio-inner {
                    width: 22px;
                    height: 22px;
                    font-size: 14px;
                }

                .ant-radio-inner::after {
                    left: 4px;
                    width: 11px;
                    height: 11px;
                }
            }
        }

        .ant-select {
            width: 100% !important;
        }
    }

    .container-loading {
        margin: 0 !important;

        .ant-spin {
            height: 40px;
            .anticon-loading {
                font-size: 40px !important;
            }
        }
    }

    .button {
        width: 100%;
        cursor: pointer;
        font-size: 16px;
        margin-top: 32px;
    }
}