@import "/src/common/styles/colors.scss";

.ant-form-item-has-error {
    input {
        border: 1px solid red !important;
    }

    label {
        color: red !important;
    }
}

.ant-form-item {
    position: relative;
    margin-bottom: 36px;

    .ant-form-item-label {
        top: 0;
        z-index: 99;
        padding: 0 6px;
        position: absolute;
        transform: translate(12px, -50%);
        background-color: getColor(tetxt, gray0);

        .ant-form-item-required {
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: getColor(text, dark);

            &:after,
            &:before {
                display: none !important;
            }
        }
    }

    .ant-form-item-control {
        text-align: left;
        position: relative;

        .ant-form-item-control,
        .ant-form-item-control-input,
        .ant-form-item-control-input-content,
        .input-label {
            &:focus-visible {
                outline: none;
            }
        }

        .input-label-junction {
            position: relative;

            .ant-spin {
                top: 50%;
                right: 8px;
                position: absolute;
                transform: translateY(-50%);
            }

        }
    }
}

.input-label {
    width: 100%;
    height: 40px;
    padding: 16px;
    font-size: 16px;
    border-radius: 4px;
    // text 
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    box-shadow: none !important;
    border: 1px solid #ccc !important;

    &::placeholder {
        font-size: 18px;
        text-align: left;
        color: getColor(text, medium) !important;
    }
}

.ant-input,
input {
    color: getColor(text, black) !important;
    background-color: getColor(background, lightest);
}

.icon-password {
    top: 50%;
    right: 8px;
    width: 25px;
    padding: 8px;
    display: flex;
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    transition: 0.2s ease-in all;

    &:hover {
        opacity: 0.6;
        transition: 0.2s ease-in all;
    }
}