@import "/src/common/styles/colors.scss";

.container-loading {
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    margin: 50px auto 10px;
    justify-content: center;
}

.ant-spin {
    svg {
        fill: getColor(primary);
    }
}