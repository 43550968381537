@import "/src/common/styles/colors.scss";

.card-stories {
    width: 30%;
    min-width: 260px;
    display: flex;
    max-width: 260px;
    overflow: hidden;
    border-radius: 4px;
    position: relative;
    flex-direction: column;
    color: getColor(text, dark);
    justify-content: space-between;

    .popup-backdrop {
        position: absolute;
        z-index: 99;
        background-color: #00000099;
        width: 100%;
        height: 100%;
    }

    .popup {
        position: absolute;
        z-index: 100;
        background-color: #fff;
        width: 90%;
        margin: 55% 5% 0 5%;
        border: 0;
        border-radius: 4px;
        height: 163px;
        text-align: center;
        padding: 30px 20px;

        button {
            background-color: #000;
            color: #fff !important;
            border-color: #fff !important;
            width: 80%;
            margin: 0 auto;

            span {
                font-size: 12px;
            }
        }

        p {
            font-size: 14px;
            font-weight: bold;
        }
    }

    .post-header {
        flex-wrap: wrap;
        display: flex;
        padding: 10px 10px;
        position: relative;
        align-items: center;
        border-radius: 4px 4px 0 0;
        background-color: #eeecec;
        justify-content: space-between;

        .influencer {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            flex-basis: 80%;
            width: 80%;
            display: flex;
            cursor: default;
            align-items: center;

            div:nth-child(2) {
                display: flex;
                flex-direction: column;
                width: 70%;

                .name {
                    font-size: 16px;
                    color: #807f80;
                    margin-left: 9px;
                    line-height: 28px;
                    overflow: hidden;
                    white-space: nowrap;
                    letter-spacing: 0.44px;
                    text-overflow: ellipsis;
                }

                .data {
                    left: 0;
                    z-index: 3;
                    width: 100%;
                    margin-left: 9px;
                    font-size: 11px;
                    // padding: 0 15px;
                }
            }
        }

        .in-campaign {
            display: flex;
            flex-direction: column;
            flex-basis: 20%;
            width: 20%;
            display: flex;

            .ant-spin {
                width: 24px;
                height: 24px;
            }

            .ant-switch {
                height: 8px;
                min-width: 24px;
                max-width: 24px;
                background-color: getColor(background, switch);

                &.ant-switch-checked {
                    background-color: getColor(primary);

                    .ant-switch-handle {
                        right: 0;
                        left: auto;
                    }
                }

                .ant-switch-handle {
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        .grow-or-decrease {
            flex-basis: 20%;
            width: 20%;
            flex-direction: column;
            align-items: center;
        }
    }

    .social-network-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-top: 0.5rem;
        align-items: center;
        justify-content: flex-start;

        .social-network {
            flex-basis: 30%;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 11px;
        }

        .spark-container {
            width: 80%;
            flex-basis: 70%;
            margin-left: 5px;
            font-size: 11px;
            align-items: flex-end;

            .spark-ad-button {
                width: 100%;
                height: 30px;
                border: 1px solid #000 !important;
                background-color: transparent;
            }

            .spark-status {
                border-radius: 10px;
                padding: 4px;
                border: 1px solid;
                text-align: center;
            }
        }
    }

    .container-post {
        height: 428px;
        position: relative;

        .content-icon-background {
            top: 40%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            width: 200px;
            height: 200px;
            position: absolute;
            border-radius: 50%;

            .content-icon {
                width: 100%;
                opacity: 0.2;
                height: 100%;
                display: flex;
                font-size: 75px;
                align-items: center;
                justify-content: center;
                color: getColor(text, white);
            }
        }

        .container-caption {
            background-color: rgba(0, 0, 0, 0.45);
            width: 100%;
            padding: 4%;
            bottom: 48px;
            position: absolute;
            z-index: 2;
            color: #fff;
            height: 68px;
            overflow: hidden;
            display: -webkit-box;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            word-wrap: break-word;
            white-space: break-spaces;
            visibility: visible;
        }

        .post-content {
            z-index: 2;
            height: 100%;
            position: relative;

            video {
                object-fit: cover;
            }

            .post-video {
                width: 100%;

                div {
                    width: 100%;
                }
            }

            .post-img {
                height: 100%;
                cursor: pointer;
                background-size: 100% 100%;
                border-radius: 0 0 4px 4px;
                background-position: center;
                background-repeat: no-repeat;
                transition: background-size 0.2s ease-in-out;
                -moz-transition: background-size 0.2s ease-in-out;
                -ms-transition: background-size 0.2s ease-in-out;
                -o-transition: background-size 0.2s ease-in-out;
                -webkit-transition: background-size 0.2s ease-in-out;

                &:hover {
                    background-size: 125% 125%;
                    transition: background-size 0.2s ease-in-out;
                    -moz-transition: background-size 0.2s ease-in-out;
                    -ms-transition: background-size 0.2s ease-in-out;
                    -o-transition: background-size 0.2s ease-in-out;
                    -webkit-transition: background-size 0.2s ease-in-out;
                }
            }

            .container-play {
                top: 40%;
                width: 0;
                height: 0;
                left: 50%;
                z-index: 1;
                cursor: pointer;
                margin-right: -1px;
                position: absolute;
                transition: all 0.2s ease-in-out;
                transform: translate(-50%, -50%);
                border-top: 20px solid transparent;
                border-bottom: 20px solid transparent;
                border-left: 35px solid getColor(text, white);

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    .post-footer {
        bottom: 0;
        z-index: 2;
        width: 100%;
        height: 48px;
        display: flex;
        padding: 0 15px;
        position: absolute;
        align-items: center;
        border-radius: 0 0 4px 4px;
        justify-content: space-between;
        background-color: rgba(0, 0, 0, 0.75);

        .junction {
            display: flex;
            font-size: 154x;
            cursor: default;
            letter-spacing: 0;
            line-height: 20px;
            align-items: center;
            color: getColor(text, white);

            &.see-all {
                cursor: pointer;
                transition: 0.2s all ease-in-out;

                &:hover {
                    opacity: 0.8;
                    transition: 0.2s all ease-in-out;
                }
            }

            .icon {
                margin-right: 5px;
                font-weight: bold;
                color: getColor(text, white);

                &.icon-overview {
                    font-size: 20px;
                }

                &.icon-advance1 {
                    margin-right: 0;
                    margin-left: 5px;
                }
            }
        }
    }
}

.ant-modal-body {
    .ask-spark-title {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .ask-spark-text {
        // font-weight: 600;
        font-size: 14px;
    }

    .ask-spark-buttons {
        display: flex;
        margin-top: 46px;
        justify-content: space-between;

        .green-button {
            height: 40px;
            width: 220px;
            font-weight: 700;
            font-size: 16px;
        }

        .outlined-green-button {
            height: 40px;
            width: 220px;
            font-weight: 700;
            font-size: 16px;
        }
    }

    .button-container {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;

        .ant-spin {
            height: auto;
            margin-right: 10px;
            color: white;

            svg {
                fill: white;
            }
        }
    }
}
