@import "/src/common/styles/colors.scss";

.end-the-subscription {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    position: absolute;
    z-index: 9999999999;
    align-items: center;
    justify-content: center;

    .smoke-img {
        position: absolute;

        &.left {
            top: 0;
            left: 0;
            width: 55%;
        }

        &.top {
            display: none;
            top: 0;
            transform: rotate(90deg);
        }

        &.right {
            right: 0;
            bottom: 0;
            width: 55%;
        }

        &.bottom {
            display: none;
            z-index: 9999999999999999;
            bottom: 0;
        }
    }

    .container-end-the-subscription {
        width: 85%;
        z-index: 9999;
        display: flex;
        overflow: hidden;
        padding: 26px 14px;
        text-align: center;
        position: relative;
        border-radius: 8px;
        align-items: center;
        box-sizing: border-box;
        flex-direction: column;
        background-color: #FFF;

        iframe {
            width: 100%;
            max-width: 400px;
            border-radius: 10px;
            min-height: 200px !important;
            max-height: 200px !important;
        }

        .title {
            display: flex;
            font-size: 16px;
            font-weight: bold;
            line-height: 24px;
            align-items: center;
            margin: 16px 0 8px;
            flex-direction: column;
            text-transform: uppercase;
        }

        .text {
            font-size: 12px;
        }
        
        .button-submit {
            width: 100%;
            height: 52px;
            border: none;
            outline: none;
            margin: 0 auto;
            cursor: pointer;
            font-weight: 600;
            margin-top: 24px;
            max-width: 280px;
            line-height: 24px;
            border-radius: 4px;
            text-transform: uppercase;
            color: getColor(text, white);
            transition: all .2s ease-in-out;
            background: getColor(secondary) !important;
            box-shadow: 0 2px 4px -1px rgba(168, 168, 168, 0.2), 0 1px 10px 0 rgba(204, 204, 204, 0.12);

            &:hover {
                opacity: 0.7;
            }

            .text-button {
                font-size: 16px;
            }
        }
    }

    @media (min-width: 834px) {
        .container-end-the-subscription {
            padding: 45px 90px;

            .icon-close {
                top: 25px;
                right: 15px;
                font-size: 30px;
                padding: 15px 24px;
            }
        }
    }

    @media (min-width: 1440px) {
        .container-end-the-subscription {
            display: flex;
            padding: 85px 98px;
            flex-direction: column;

            .icon-close {
                width: 100%;
                display: flex;
                max-width: 1440px;
                position: relative;
                margin: 0 auto -20px;
                justify-content: flex-end;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .end-the-subscription {
        .container-end-the-subscription {
            width: 65%;
            padding: 32px;
            border-radius: 8px;

            .title {
                font-size: 20px;
                margin-bottom: 26px;
            }

            .text {
                height: 80%;
                font-size: 14px;
            }
        }
    }
}

@media (min-width: 834px) {
    .container-end-the-subscription {
        padding: 45px 90px;

        .icon-close {
            top: 25px;
            right: 15px;
            font-size: 30px;
            padding: 15px 24px;
        }
    }
}

@media screen and (min-width: 1024px) {
    .end-the-subscription {
        top: 0;
        left: 0;
        width: 100%;
        padding: 0;
        height: 100%;
        display: flex;
        overflow: hidden;
        min-height: 100vh;
        position: absolute;
        z-index: 9999999999;
        align-items: center;
        justify-content: center;
        background-color: #FFF;

        .smoke-img {
            position: absolute;

            &.left {
                top: -50%;
                left: -20%;
                width: 55%;
                transform: rotate(45deg);
            }

            &.top {
                display: none;
                top: 0;
                transform: rotate(90deg);
            }

            &.right {
                right: 0;
                bottom: 0;
                width: 55%;
            }

            &.bottom {
                display: none;
                z-index: 9999999999999999;
                bottom: 0;
            }
        }

        .container-end-the-subscription {
            width: 100%;
            height: 100%;
            z-index: 9999;
            display: flex;
            padding: 30px 42px;
            overflow: auto;
            text-align: center;
            position: relative;
            border-radius: 15px;
            align-items: center;
            box-sizing: border-box;
            flex-direction: column;
            background-color: transparent;

            iframe {
                width: 80%;
                max-width: 550px;
                height: 300px !important;
                max-height: 300px !important;
                min-height: 300px !important;
            }

            .title {
                display: flex;
                font-size: 30px;
                font-weight: bold;
                line-height: 36px;
                align-items: center;
                margin: 43px 0 30px;
                flex-direction: column;
            }

            .text {
                margin: 0;
                height: auto;
                font-size: 16px;
                max-width: 514px;
            }

            .button-submit {
                width: 100%;
                height: 46px;
                border: none;
                outline: none;
                margin: 0 auto;
                cursor: pointer;
                font-size: 18px;
                font-weight: 600;
                margin-top: 30px;
                max-width: 384px;
                line-height: 24px;
                border-radius: 4px;
                color: getColor(text, white);
                transition: all .2s ease-in-out;
                background: getColor(secondary) !important;

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}

@media (min-width: 1440px) {
    .container-end-the-subscription {
        display: flex;
        padding: 85px 98px;
        flex-direction: column;

        .icon-close {
            width: 100%;
            display: flex;
            max-width: 1440px;
            position: relative;
            margin: 0 auto -20px;
            justify-content: flex-end;
        }
    }
}
