@import "/src/common/styles/colors.scss";

.google-login {
    width: 100%;
    display: flex;
    align-items: center;

    .google-btn {
        width: 100%;
        height: 50px;
        display: flex;
        cursor: pointer;
        padding: 0 12px;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out;
        background-color: getColor(background, lightest) !important;
        border: 1px solid getColor(background, lightGrey) !important;
        .google-icon{
            width: 24px;
        }
        .btn-text {
            font-size: 14px;
            padding-left: 8px;
            text-align: center;
            width: calc(100% - 24px);
            text-transform: uppercase;
        }
        
        .container-loading {
            margin: 0;
            margin-left: 8px;
            width: calc(100% - 24px);
            .ant-spin {
                width: 24px;
                height: 24px;
            }
        }

        &:hover {
            opacity: .6 !important;
        }

        
        &.disabled {
            cursor: default;

            &:hover {
                opacity: 1 !important;
            }
        }
    }

    .icon-check {
        font-size: 20px;
        font-weight: bold;
        margin-left: 12px;
        color: getColor(status, success);
    }
}
