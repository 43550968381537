@import "/src/common/styles/colors.scss";

.campaign-card {
    margin-bottom: 48px;

    .card-header {
        display: flex;
        margin-bottom: 19px;
        padding-bottom: 5px;
        align-items: flex-end;
        color: getColor(text, dark);
        justify-content: space-between;
        border-bottom: 1px solid getColor(background, light);

        .name-status {
            margin: 0;
            display: flex;
            flex-direction: column;
            font-size: 20px;

            .type-objective {
                font-size: 12px;
                letter-spacing: 0;
                line-height: 16px;
                padding-bottom: 8px;
            }

            .status {
                display: flex;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 16px;
                align-items: center;

                .pointer {
                    width: 8px;
                    height: 8px;
                    margin-left: 4px;
                    border-radius: 50%;
                    background-color: getColor(primary);
                }

                &.active .pointer {
                    background-color: getColor(status, success);
                }

                &.filed .pointer {
                    background-color: getColor(text, dark);
                }

                &.inactive .pointer {
                    background-color: getColor(status, error);
                }
            }

            .name {
                display: flex;
                font-weight: 500;
                letter-spacing: 0;
                overflow: hidden;
                text-align: center;
                align-items: center;
                .ant-spin {
                    height: 46px;
                    margin-left: 17px;
                }
                .ant-btn {
                    padding: 0 6px;
                }
                .icon-more {
                    padding: 8px;
                    font-weight: bold;
                    transition: 0.2s all ease-in-out;

                    &.icon-more-open {
                        border-radius: 50%;
                        transform: rotate(90deg);
                        background-color: getColor(background, light);
                    }
                }
            }
        }

        .date-performance {
            display: flex;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 16px;
            padding-bottom: 10px;
            align-items: flex-end;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .card-body {
        .card-metrics {
            display: flex;
            margin-bottom: 19px;
            gap: 10px;
            justify-content: stretch;
            -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
            flex-grow: 1;

            .metric {
                width: auto;
                flex: 1;
                flex-basis: auto;
                order: 1;
                &::after {
                    content: "";
                    flex: auto;
                }
            }

            .metric-best_performance {
                margin-right: 0;
                order: 2;

                .card-value-influencers .ant-avatar {
                    margin: 0 5px;
                }
            }

            // &.card-site {
            //     .metric {
            //         width: 25%;
            //     }
            // }

            // &.card-4 {
            //     .metric {
            //         width: 25%;
            //     }
            // }
        }

        .card-buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .text-draft {
                margin: 0;
                font-size: 20px;
                font-weight: bold;
                text-transform: uppercase;
                color: getColor(text, medium, 0.4);
            }

            .p-click {
                margin: 0;
                display: flex;
                font-size: 18px;
                font-weight: 500;
                cursor: pointer;
                align-items: center;
                color: getColor(text, dark);
                transition: 0.3s ease-in-out all;
                .icon {
                    font-weight: bold;
                    margin-left: 7px;
                }
                &:hover {
                    color: getColor(primary);
                }
            }

            .buttons-nw {
                display: flex;

                .ant-btn {
                    margin-right: 12px;
                    padding: 8px 17px 6px;
                    border-bottom: 6px solid;
                    border-radius: 4px 4px 0 0;
                }
            }
        }
    }

    // &.campaign-hashtag {
    //     .card-body {
    //         // .card-metrics .metric {
    //         //     width: 50%;
    //         //     margin-right: 7px;
    //         // }
    //     }
    // }

    &.campaign-draft {
        .card-content {
            .card-header {
                padding: 0;
                align-items: flex-start;
            }
        }
    }
}
