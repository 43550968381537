@import "/src/common/styles/colors.scss";

.home-page {
    width: 100%;
    height: 100%;

    .home-content {
        padding: 48px;
        margin: 0 auto;
        max-width: 1288px;
        overflow-x: hidden;
        box-sizing: content-box;

        .home-buttons {
            display: flex;
            align-items: center;
            margin-bottom: 48px;
            justify-content: space-between;
            button[disabled] {
                pointer-events: none;
            }
            .ant-spin {
                margin-top: 5px;
                .ant-spin-dot {
                    font-size: 16px;
                    i {
                        width: 8px;
                        height: 8px;
                        background-color: getColor(text, dark);
                    }
                }
            }

            .search-filter {
                display: flex;
                align-items: center;
                justify-content: space-between;

                span:nth-child(1) {
                    margin-right: 8px;
                }

                .text-dropdown {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 20px;
                    line-height: 24px;
                    margin-left: 8px;
                    letter-spacing: 0.15px;
                    color: getColor(text, white);
                }
            }
        }
    }
}
