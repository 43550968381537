@import "/src/common/styles/colors.scss";

.ant-spin-dot-item {
    background-color: getColor(primary);
}

.home-campaigns {
    .container-loading {
        margin: 150px 0;
    }
    
    .text-empty-cmps {
        font-size: 35px;
        font-weight: 500;
        margin-top: 150px;
        text-align: center;
        color: getColor(text, dark);
    }
    .pagination {
        display: flex;
        justify-content: center;
    }
}
