@import "/src/common/styles/colors.scss";

.tiktok-discovery {
    .btn-back {
        cursor: pointer;
        color: getColor(primary);
        transition: all 0.2s ease-in-out;
        .icon {
            margin-right: 8px;
        }
        &:hover {
            opacity: .6;
        }
    }

    .subtitle {
        margin: 24px 0;
        font-size: 18px;
        font-weight: 400;
    }
}