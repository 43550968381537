@import "/src/common/styles/colors.scss";

.calendar {
    font-size: 16px;
    cursor: pointer;
    padding: 6px 12px;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: none !important;
    background-color: getColor(background, lightest);
    border: 1px solid getColor(background, grey) !important;

    .ant-picker-input input {
        cursor: pointer;
        text-align: center;
    }

    .ant-picker-active-bar {
        background-color: transparent !important;
    }

    .ant-picker-range-separator {
        font-size: 14px;
        color: getColor(background, grey);
    }

    &.only-one {
        padding: 0;
        border: none !important;

        .ant-picker-range-separator {
            display: none !important;
        }

        .ant-picker-input {
            width: 100%;
            margin-right: 16px;
            input {
                width: 100%;
                height: 40px;
                outline: none;
                text-align: left;
                padding: 6px 12px;
                border-radius: 4px;
                box-sizing: border-box;
                font-size: 16px !important;
                box-shadow: none !important;
                background-color: getColor(background, light) !important;
                border: 1px solid getColor(background, medium) !important;

                &::placeholder {
                    font-size: 16px;
                    text-align: left;
                    color: getColor(text, medium);
                }

                &:disabled {
                    opacity: 0.6;
                    cursor: default;
                }
            }
        }

        &.input-error {
            .ant-picker-input input {
                border: 1px solid getColor(status, error) !important;
            }
        }
    }
}

// Header
.ant-picker-panels {
    .ant-picker-date-panel .ant-picker-header {
        border: none !important;
        padding-top: 10px;
    }
}

.ant-picker-header-view {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
}

// title
.ant-picker-year-btn,
.ant-picker-month-btn {
    margin: 0 4px;
    text-transform: uppercase;
    color: getColor(text, dark) !important;
}

// double arrow
.ant-picker-header-super-next-btn,
.ant-picker-header-super-prev-btn {
    display: none;
}

// arrow
button.ant-picker-header-next-btn,
button.ant-picker-header-prev-btn {
    &:hover {

        .ant-picker-next-icon,
        .ant-picker-prev-icon {
            &::before {
                border-color: getColor(primary) !important;
            }
        }
    }
}

// day
thead {
    tr th {
        font-size: 12px;
        margin-bottom: 12px;
        padding-bottom: 5px;
        color: getColor(text) !important;
    }
}


// Range Picker 

// selected day
td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-selected,
td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start,
td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end {
    .ant-picker-cell-inner {
        border-radius: 50% !important;
        background-color: getColor(primary) !important;

        &::before {
            border: none;
        }
    }

    &::after {
        border-radius: 50% !important;
        border-color: getColor(primary, light) !important;
    }

    &::before {
        height: 25px;
        background-color: getColor(primary, light) !important;
    }
}

// hover
.ant-picker-cell-range-hover-start {

    &::before,
    .ant-picker-cell-inner {
        background-color: getColor(primary, light) !important;
    }
}


// hover only init day selected
td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover,
td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-end {
    &::before {
        background-color: getColor(primary, light) !important;
        border: none !important;
    }

    &.td.ant-picker-cell:first-child {}
}

// between the beginning and the end
td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range {

    &::before,
    .ant-picker-cell-inner {
        background-color: getColor(primary, light) !important;
    }

    &::before {
        height: 25px;
    }
}

// between the beginning and the end - hover
td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start {

    .ant-picker-cell-inner {
        &::after {
            width: 100%;
            border-radius: 50%;
            background-color: transparent !important;
            border: 1px solid getColor(primary, medium) !important;
        }
    }
}

// today
.ant-picker-cell-inner {
    &::before {
        border-radius: 50% !important;
        border-color: getColor(primary) !important;
    }
}




// Date picker

// selected day
td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected {
    .ant-picker-cell-inner {
        background-color: getColor(primary) !important;
    }
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
    background-color: getColor(primary, light) !important;
}

// hover
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    border-radius: 50%;
    background-color: getColor(primary, light) !important;
}

// remove footer
.ant-picker-footer {
    display: none;
}


tbody tr {
    td::after {
        border: none !important;
    }

    td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range:first-child {
        border-radius: 40% 0 0 40%;

        &::before,
        .ant-picker-cell-inner {
            border-radius: 40% 0 0 40%;
        }
    }

    td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range:last-child,
    td.ant-picker-cell.ant-picker-cell-end.ant-picker-cell-in-view.ant-picker-cell-in-range,
    .ant-picker-cell-range-hover-end {

        &::before,
        .ant-picker-cell-inner {
            border-radius: 0 40% 40% 0;
        }
    }


    td.ant-picker-cell.ant-picker-cell-start.ant-picker-cell-in-view.ant-picker-cell-in-range {

        &::before,
        .ant-picker-cell-inner {
            border-radius: 40% 0 0 40%;
        }
    }

    td.ant-picker-cell.ant-picker-cell-start.ant-picker-cell-in-view.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start {

        &::before,
        .ant-picker-cell-inner {
            border-radius: 40% 0 0 40%;
        }
    }

    td.ant-picker-cell.ant-picker-cell-end.ant-picker-cell-in-view.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end {

        &::before,
        .ant-picker-cell-inner {
            border-radius: 0 40% 40% 0;
        }
    }

    td.ant-picker-cell-range-hover {
        &:last-child {

            &::before,
            .ant-picker-cell-inner {
                border-radius: 0 40% 40% 0;
            }
        }

        &:first-child {

            &::before,
            .ant-picker-cell-inner {
                border-radius: 40% 0 0 40%;
            }
        }
    }

    td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range {
        // background-color: red;
    }
}