.google-login-container{
    background-color: #f1f1f1;
    width: 100%;
    min-height: 100%;
    overflow: scroll;
    font-size: 14px;

    .header {
        background-color: white;
        height: 90px;
        display: flex;
        align-items: center;

        .logo-hypolake {
            height: 35px;
            margin-left: 80px;
        }

    }

    .body {
        max-width: 100%;
        min-height: 100%;
        margin-top: 64px;
        margin-left: 76px;
        margin-right: 76px;

        .body-title {
            max-width: 100%;
            text-align: center;
            color: #6A53BD;
            font-size: 32px;
            font-weight:600;
            margin-bottom: 72px;
        }

        .center-div {
            max-width: 100%;
        }

        .violet-link {
            color: #6A53BD;
            text-decoration: underline;
        }

    }

    .footer {
        background-color: #5E4AB0;
        height: 291px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .text-helper {
            margin-top: 48px;
            margin-bottom: 48px;
            color: white;
        }

        .white-link {
            color: white;
            text-decoration: underline;
        }
    }
}