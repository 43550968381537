@import "/src/common/styles/colors.scss";

.container-add-influencer {
    margin-top: 32px;

    .label-input {
        margin: 0;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.44px;
    }

    .email-label {
        margin-top: 20px;
    }

    .ant-input {
        height: 40px;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0;
        box-shadow: none !important;
        border: 1px solid getColor(background, medium) !important;
        background-color: getColor(background, light) !important;
        &.disabled {
            cursor: not-allowed;
            color: getColor(text, black);
        }

        &::placeholder {
            font-size: 16px;
            color: getColor(text, medium);
        }

        &.error {
            border: 1px solid getColor(status, error) !important;
        }
    }

    .container-data-posts {
        display: flex;
        margin-top: 32px;
    }

    .container-data-posts {
        display: flex;
        justify-content: space-between;

        .card-post-data {
            width: 25%;
            border-radius: 4px;
            border: 1px solid getColor(background, medium);

            .card-header {
                height: 40px;
                display: flex;
                padding: 0 13px;
                align-items: center;
                justify-content: space-between;
                background-color: getColor(background, titleTable);

                .card-header-title {
                    margin: 0;
                }
            }

            .card-body {
                width: 100%;
                display: flex;
                overflow: hidden;
                position: relative;
                padding: 18px 12px 24px;
                justify-content: space-between;
                .input-currency {
                    z-index: 1;
                    width: 48%;
                    position: relative;
                    &.input-error {
                        border-color: getColor(status, error) !important;
                    }
                }
                .span-error {
                    bottom: 5px;
                    font-size: 12px;
                    left: calc(52%);
                    position: absolute;
                    color: #f22e49 !important;
                }
            }

            &.card-total {
                width: 20%;
                opacity: 1 !important;
            }

            &.blocked {
                opacity: 0.6;
                cursor: not-allowed;
                .card-body .input-currency {
                    cursor: not-allowed;
                }
            }
        }
    }

    .junction-type-save {
        display: flex;
        margin-top: 32px;
        align-items: center;
        justify-content: space-between;

        .contract-type .text {
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.44px;
        }
    }
    .posts-error {
        color: #f22e49 !important;
        margin-top: 20px;
        margin-bottom: 0px;
    }
}
