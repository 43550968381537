@import "/src/common/styles/colors.scss";

.tag-connected {
    border-radius: 20px;
    background-color: getColor(tags, connected);
    border: 1px solid getColor(tags, connectedBorder);
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    height: 25px;
    width: 100px;

    img {
        width: 15px;
        height: auto;
    }
}
.tag-error {
    border-radius: 20px;
    background-color: getColor(tags, waiting);
    border: 1px solid getColor(tags, waitingBorder);
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    height: 25px;
    width: 100px;

    img {
        width: 15px;
        height: auto;
    }
}

.tag-waiting {
    width: fit-content;
    height: 25px;
    display: flex;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 12px;
    padding-left: 3px;
    padding-right: 3px;
    align-items: center;
    border: 1px solid getColor(text, violet);
    border-radius: 4px;
    color: getColor(text, violet);
    cursor: pointer;

    span {
        margin-left: 5px;
        padding-right: 5px;
    }
}

.tag-expired {
    width: fit-content;
    height: 25px;
    display: flex;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 12px;
    padding-left: 3px;
    padding-right: 3px;
    align-items: center;
    border: 1px solid getColor(text, violet);
    border-radius: 4px;
    color: getColor(text, violet);
    cursor: pointer;

    span {
        margin-left: 5px;
        padding-right: 5px;
    }
}

.tag-waiting-ttcm {
    border: 1px solid getColor(tags, waitingBorder);
    background-color: getColor(tags, waiting);
    width: 240px;
    height: 25px;
    border-radius: 20px;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    img {
        width: 15px;
        height: auto;
    }
}
