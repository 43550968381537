@import "/src/common/styles/colors.scss";

iframe {
    display: none;
}

.ant-layout .register-page {
    overflow-y: auto;
    background-color: #fff;

    .register-aside {
        margin: 0 auto;
        max-width: 550px;
        padding: 56px 16px 32px;

        header {
            top: 0;
            left: 0;
            width: 100%;
            height: 56px;
            display: flex;
            z-index: 99999;
            padding: 0 16px;
            position: absolute;
            align-items: center;
            background: #f0f2f5;
            box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.063538), 0px 1px 8px rgba(0, 0, 0, 0.126502);

            .logo {
                z-index: 9;
                width: 124px;
                margin: 40x auto 80px 36px;

                &:hover {
                    opacity: 0.7;
                    background-color: transparent !important;
                }
            }
        }

        .smoke-img {
            display: none;
        }

        .hypo-text-about {
            padding: 32px 0 0;

            .title,
            .text {
                max-width: 518px;
            }

            .title {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 8px;
                letter-spacing: 0.18px;
            }

            .text {
                margin: 0;
                font-size: 16px;
                line-height: 20px;
                font-weight: normal;
                letter-spacing: 0.18px;
            }
        }
    }

    .register-content {
        margin: 0 auto;
        max-width: 550px;
        padding: 0px 16px 32px;

        .register-title {
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0.18px;
        }

        .register-subtitle {
            font-size: 16px;
            max-width: 432px;
            font-weight: 500;
            margin: 8px 0 30px;
            letter-spacing: 0.18px;
        }

        .plan-price {
            // left: 50%;
            width: 100%;
            display: flex;
            font-size: 12px;
            max-width: 950px;
            line-height: 24px;
            position: relative;
            align-items: center;
            margin: 0 auto 24px;
            letter-spacing: 0.15px;
            justify-content: flex-end;
            color: getColor(text, dark);

            span {
                width: 50%;
                text-align: center;
                background-color: #e7e8ee;
                border: 1px solid rgba(16, 23, 85, 0.1);
            }

            .text-plan {
                margin: 0;
                padding: 6px 8px;
                border-right: none;
                white-space: nowrap;
                border-radius: 4px 0 0 4px;
            }

            .text-price {
                padding: 6px 8px;
                white-space: nowrap;
                border-radius: 0 4px 4px 0;
            }
        }

        .success-auth-google,
        .google-login {
            max-width: 280px;
            margin: 20px auto 50px;
        }

        .container-steps {
            max-width: 550px;
            position: relative;
            margin: 60px auto 0;

            .line {
                top: 25px;
                left: 50%;
                width: 70%;
                height: 2px;
                display: flex;
                position: absolute;
                align-items: center;
                background-color: #e2e2e2;
                transform: translate(-50%, -50%);

                .line-colored {
                    height: 2px;
                    transition: all 0.2s ease-in-out;
                    background-color: getColor(secondary);

                    &.w-0 {
                        width: 0%;
                    }

                    &.w-1 {
                        width: 100%;
                    }

                    &.w-2 {
                        width: 100%;
                    }
                }
            }

            .steps {
                z-index: 99;
                height: 100px;
                display: flex;
                border-radius: 4px;
                margin-bottom: 16px;
                flex-direction: row;
                justify-content: space-between;

                .step {
                    width: 30%;
                    z-index: 99;
                    display: flex;
                    cursor: default;
                    position: relative;
                    align-items: center;
                    flex-direction: column;
                    transition: 0.2s all ease-in-out;

                    &.complet {
                        display: flex;

                        .step-num {
                            background-color: getColor(secondary);
                            border: 1px solid getColor(secondary);
                        }
                    }

                    &.cursor-pointer {
                        cursor: pointer;
                    }

                    &.atual {
                        display: flex;

                        .step-num {
                            transition: 0.2s all ease-in-out;
                            background-color: getColor(secondary);
                            border: 1px solid getColor(secondary);
                        }
                    }

                    .step-num {
                        width: 44px;
                        height: 44px;
                        display: flex;
                        font-size: 18px;
                        font-weight: 700;
                        border-radius: 50%;
                        margin-bottom: 4px;
                        align-items: center;
                        justify-content: center;
                        background-color: #e2e2e2;
                        color: getColor(text, white);
                        transition: 0.3s all ease-in-out;
                    }

                    .step-title {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 24px;
                        font-style: normal;
                        letter-spacing: 0.15px;
                        text-transform: capitalize;
                        color: getColor(text, block);
                        transition: 0.2s all ease-in-out;

                        &.atual {
                            color: getColor(text);
                        }
                    }
                }
            }
        }

        .ant-form {
            .junction-name {
                gap: 16px;
                display: flex;
                flex-direction: column;
                
                .ant-row {
                    width: 100% !important;
                }
            }

            .password-requests {
                .pr-title {
                    font-size: 15px;
                }

                ul {
                    font-size: 15px;
                    padding: 8px 0 0 20px;
                }
            }

            .ant-select-selector,
            .ant-form-item-label,
            .ant-input-prefix,
            .ant-input {
                background-color: #fff !important;
                // background-color: #F5F5F5 !important;
            }

            .ant-input-prefix {
                margin: 0;
            }

            .ant-form-item {
                .ant-form-item-label label {
                    font-size: 12px;
                }

                .ant-input-affix-wrapper {
                    height: 50px;
                }

                .ant-form-item-explain.ant-form-item-explain-error {
                    font-size: 12px;
                }

                .ant-form-item-control .ant-form-item-control-input {
                    height: 50px;

                    .input-label {
                        height: 50px;
                        font-size: 15px;
                        background-color: #fff !important;

                        &::placeholder {
                            font-size: 15px;
                        }

                        &:read-only {
                            cursor: not-allowed;
                            background-color: getColor(background, medium) !important;
                        }
                    }
                }
            }
        }

        .section-bottom {
            display: flex;
            font-size: 16px;
            line-height: 20px;
            align-items: center;
            flex-direction: column;
            // justify-content: center;

            .text-terms-privacy {
                font-size: 14px;

                b {
                    font-size: 14px;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;

                    &:hover {
                        color: getColor(primary);
                    }
                }
            }

            .checkbox-container {
                margin: 0px auto 40px;

                .checkbox-text {
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            .button-submit {
                width: 100%;
                height: 52px;
                border: none;
                outline: none;
                margin: 0 auto;
                cursor: pointer;
                font-weight: 600;
                max-width: 280px;
                line-height: 24px;
                border-radius: 4px;
                text-transform: uppercase;
                color: getColor(text, white);
                transition: all 0.2s ease-in-out;
                background-color: getColor(secondary) !important;
                box-shadow: 0 2px 4px -1px rgba(168, 168, 168, 0.2), 0 1px 10px 0 rgba(204, 204, 204, 0.12);

                &:hover {
                    opacity: 0.7;
                }

                .text-button {
                    font-size: 16px;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .ant-layout .register-page {
        .register-aside {
            margin: 0 auto;
            max-width: 800px;
            padding: 74px 32px 32px;

            header {
                height: 60px;
                padding: 0 32px;

                .logo {
                    width: 140px;
                }
            }

            .hypo-text-about {
                padding: 32px 0 0;

                .title,
                .text {
                    max-width: none;
                }

                .title {
                    font-size: 22px;
                    margin-bottom: 12px;
                }

                .text {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }

        .register-content {
            margin: 0 auto;
            max-width: 800px;
            padding: 0px 32px 32px;

            .register-title {
                font-size: 22px;
            }

            .register-subtitle {
                font-size: 18px;
                max-width: 432px;
                margin: 8px 0 40px;
            }

            .plan-price {
                font-size: 15px;
                max-width: 450px;
                margin: 20px auto 20px;

                .text-plan {
                    padding: 8px 10px;
                }

                .text-price {
                    padding: 8px 10px;
                }
            }

            .google-login {
                max-width: 280px;
                margin: 20px auto 50px;
            }

            .container-steps {
                max-width: 600px;
                margin: 80px auto 30px;

                .line {
                    top: 26px;
                    height: 4px;

                    .line-colored {
                        height: 4px;
                    }
                }

                .steps {
                    .step {
                        .step-num {
                            width: 52px;
                            height: 52px;
                            font-size: 18px;
                        }

                        .step-title {
                            font-size: 16px;
                            line-height: 32px;
                        }
                    }
                }
            }

            .ant-form {
                .junction-name {
                    display: flex;
                    flex-direction: row;

                    .ant-form-item, .ant-row {
                        width: 100%;
                    }
                }

                .password-requests {
                    .pr-title {
                        font-size: 16px;
                    }

                    ul {
                        font-size: 16px;
                    }
                }

                .ant-form-item {
                    .ant-form-item-label label {
                        font-size: 14px;
                    }

                    .ant-form-item-explain.ant-form-item-explain-error {
                        font-size: 14px;
                    }

                    .ant-form-item-control .ant-form-item-control-input {
                        .input-label {
                            font-size: 16px;

                            &::placeholder {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            .section-bottom {
                line-height: 20px;

                .text-terms-privacy {
                    font-size: 16px;

                    b {
                        font-size: 15px;
                    }
                }

                .checkbox-container {
                    margin: 0px auto 40px;

                    .checkbox-text {
                        font-size: 16px;
                    }
                }

                .button-submit {
                    font-weight: 600;
                    max-width: 360px;

                    .text-button {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .ant-layout {
        background: transparent;

        .register-page {
            width: 100%;
            height: 100%;
            display: grid;
            overflow: hidden;
            grid-template-columns: 1fr 1fr;

            .register-aside {
                width: 100%;
                padding: 40px;
                height: 100vh;
                max-width: none;
                overflow: hidden;
                position: relative;
                background-color: getColor(background, lightest);

                header {
                    padding: 0;
                    height: auto;
                    box-shadow: none;
                    position: relative;
                    background-color: transparent;

                    .logo {
                        z-index: 9;
                        width: 221px;
                        display: block;
                        cursor: pointer;
                        position: relative;
                        margin: 40x auto 80px 36px;
                        transition: 0.2s all ease-in-out;

                        &:hover {
                            opacity: 0.7;
                            background-color: transparent !important;
                        }
                    }
                }

                .smoke-img {
                    height: 95%;
                    display: block;
                    position: absolute;

                    &.purple-smoke {
                        right: 0;
                        bottom: 0;
                    }

                    &.green-smoke {
                        left: 0;
                        top: 0;
                    }
                }

                .hypo-text-about {
                    top: 20%;
                    position: relative;

                    .title,
                    .text {
                        max-width: 518px;
                    }

                    .title {
                        font-size: 32px;
                        line-height: 34px;
                        font-weight: bold;
                        margin-bottom: 41px;
                        letter-spacing: 0.18px;
                    }

                    .text {
                        font-size: 18px;
                        line-height: 31px;
                        font-weight: normal;
                        letter-spacing: 0.18px;
                    }
                }
            }

            .register-content {
                right: 0;
                width: 100%;
                height: 100%;
                max-width: none;
                overflow-y: auto;
                text-align: center;
                padding: 40px 95px;
                background: #f0f2f5;

                .register-title {
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 53px;
                    letter-spacing: 0.18px;
                }

                .register-subtitle {
                    font-size: 24px;
                    max-width: 432px;
                    font-weight: 500;
                    line-height: 27px;
                    letter-spacing: 0.18px;
                    margin: 40px auto 50px;
                }

                .plan-price {
                    font-size: 14px;
                    max-width: 950px;
                    line-height: 24px;
                    margin: 60px auto 30px;

                    .text-plan {
                        padding: 12px 16px;
                    }

                    .text-price {
                        padding: 12px 16px;
                    }
                }

                .container-steps {
                    max-width: 950px;

                    .line {
                        top: 25px;
                        left: 50%;
                        width: 70%;
                        height: 5px;

                        .line-colored {
                            height: 5px;
                        }
                    }

                    .steps {
                        height: 100px;
                        border-radius: 4px;
                        margin-bottom: 32px;

                        .step {
                            width: 30%;
                            z-index: 99;

                            .step-num {
                                width: 50px;
                                height: 50px;
                                display: flex;
                                font-size: 18px;
                                font-weight: 700;
                                margin: 0 0 24px 0;
                            }

                            .step-title {
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 24px;
                                letter-spacing: 0.15px;
                            }
                        }
                    }
                }

                .ant-form {
                    .ant-input-prefix {
                        margin: 0;
                    }

                    .ant-select-selector,
                    .ant-form-item-label,
                    .ant-input-prefix,
                    .ant-input {
                        background-color: #f5f5f5 !important;
                    }

                    .ant-input-prefix {
                        margin: 0;
                    }

                    .ant-form-item {
                        .ant-form-item-label label {
                            font-size: 12px;
                        }

                        .ant-input-affix-wrapper {
                            height: 50px;
                        }

                        .ant-form-item-explain.ant-form-item-explain-error {
                            font-size: 12px;
                        }

                        .ant-form-item-control .ant-form-item-control-input {
                            height: 50px;

                            .input-label {
                                height: 50px;
                                font-size: 15px;
                                background-color: #f5f5f5 !important;

                                &::placeholder {
                                    font-size: 15px;
                                }
                                &:read-only {
                                    background-color: transparent !important;
                                }
                            }
                        }
                    }
                }

                .section-bottom {
                    font-size: 16px;
                    line-height: 20px;

                    .text-terms-privacy {
                        font-size: 16px;

                        b {
                            font-size: 15px;
                        }
                    }

                    .checkbox-container {
                        margin: 0px auto 40px;

                        .checkbox-text {
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }

                    .button-submit {
                        height: 56px;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
