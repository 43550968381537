@import "/src/common/styles/colors.scss";

.text-terms-privacy {
    margin: 20px auto 20px;
}

.password-requests {
    font-size: 18px;
    text-align: left;
    margin-top: -12px;
    margin-bottom: 32px;
    line-height: 24.5px;
    letter-spacing: 0.2px;

    .pr-title {
        margin: 0;
        font-weight: bold;
    }

    ul {
        li {
            transition: all 0.2 ease-in-out;

            &.checked {
                color: getColor(status, success);
            }
        }
    }
}

.ant-form {
    margin: 0 auto;
    max-width: 950px;
    .junction-name {
        display: flex;
        justify-content: space-between;
        .ant-row {
            width: 49%;
        }
    }
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
    background-color: red !important;
}