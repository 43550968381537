@import "/src/common/styles/colors.scss";

.modal-values {
    width: 423px !important;

    .ant-modal-header {
        border: none;
        padding: 24px 24px 12px;
    }

    .ant-form {
        display: flex;
        align-items: center;
        margin-bottom: 36px;
        justify-content: space-between;

        .ant-form-item {
            width: 40%;
            margin: 0 !important;
            input {
                width: 100%;
                height: 56px;
                outline: none;
                padding: 6px 12px;
                border-radius: 4px;
                box-sizing: border-box;
                font-size: 16px !important;
                box-shadow: none !important;
                background-color: getColor(background, lightest);
                border: 1px solid getColor(background, lightGrey) !important;
            }
            .ant-form-item-explain-error {
                bottom: -24px;
                font-size: 11px;
                position: absolute;
            }
        }
    }

    .ant-btn {
        width: 100%;
        border-radius: 8px;
    }
}