@import "/src/common/styles/colors.scss";
@import "/src/common/styles/icons.scss";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

*,
body {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    // remove focus
    --antd-wave-shadow-color: transparent !important;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
    width: 100% !important;
}

#root {
    height: 100%;
}

.background-page {
    width: 100%;
    overflow: hidden;
    background-color: #f5f5f5;
}

.scroll-content {
    width: 100%;
    overflow-y: auto;
    height: calc(100% - 64px);
}

/* width */
::-webkit-scrollbar {
    width: 11px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(97, 97, 97);
}

// per networks
.color-instagram,
.color-ig {
    color: getColor(socials, instagram) !important;
}
.border-color-instagram,
.border-color-ig {
    border-color: getColor(socials, instagram) !important;
}
.background-instagram-opacity,
.background-ig-opacity {
    background-color: getColor(socials, instagram, 0.7) !important;
}
.background-instagram,
.background-ig {
    background-color: getColor(socials, instagram) !important;
}
//
.color-facebook,
.color-fb {
    color: getColor(socials, facebook) !important;
}
.border-color-facebook,
.border-color-fb {
    border-color: getColor(socials, facebook) !important;
}
.background-facebook-opacity,
.background-fb-opacity {
    background-color: getColor(socials, facebook, 0.7) !important;
}
.background-facebook,
.background-fb {
    background-color: getColor(socials, facebook) !important;
}
//
.color-twitter,
.color-tw {
    color: getColor(socials, twitter) !important;
}
.border-color-twitter,
.border-color-tw {
    border-color: getColor(socials, twitter) !important;
}
.background-twitter-opacity,
.background-tw-opacity {
    background-color: getColor(socials, twitter, 0.7) !important;
}
.background-twitter,
.background-tw {
    background-color: getColor(socials, twitter) !important;
}
//
.color-youtube,
.color-yt {
    color: getColor(socials, youtube) !important;
}
.border-color-youtube,
.border-color-yt {
    border-color: getColor(socials, youtube) !important;
}
.background-youtube-opacity,
.background-yt-opacity {
    background-color: getColor(socials, youtube, 0.7) !important;
}
.background-youtube,
.background-yt {
    background-color: getColor(socials, youtube) !important;
}
//
.color-tiktok,
.color-tt {
    color: getColor(socials, tiktok) !important;
}
.border-color-tiktok,
.border-color-tt {
    border-color: getColor(socials, tiktok) !important;
}
.background-tiktok-opacity,
.background-tt-opacity {
    background-color: getColor(socials, tiktok, 0.7) !important;
}
.background-tiktok,
.background-tt {
    background-color: getColor(socials, tiktok) !important;
}
//

.deselect-text {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.highcharts-credits {
    display: none;
}

.empty-value {
    width: 100%;
    height: 150px;
    display: flex;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    color: getColor(text, dark);
}

.ant-form-item-explain.ant-form-item-explain-error {
    color: getColor(status, error) !important;
}

//Shepherd.js fix for popper position
.shepherd-header {
    display: none;
}
.shepherd-target {
    padding: 0 5px;
}
.shepherd-text {
    color: #8f8f8f;
}
div[data-popper-placement="right"] {
    margin-left: 15px !important;
}
div[data-popper-placement="left"] {
    margin-left: -15px !important;
}
div[data-popper-placement="bottom"] {
    margin-top: 15px !important;
}
div[data-popper-placement="top"] {
    margin-top: -15px !important;
}

div[data-popper-placement="left-start"][data-shepherd-step-id="list-campaigns-list"]
    > .shepherd-arrow {
    transform: translateY(-39.5px);
}

div[data-popper-placement="left-start"][data-shepherd-step-id="campaign-summary-content"] {
    > .shepherd-arrow {
        transform: translateY(-60px);
    }
}
