@import "/src/common/styles/colors.scss";

.card-funnel {
    box-shadow: 0 2px 4px -1px rgba(168, 168, 168, 0.2), 0 1px 10px 0 rgba(204, 204, 204, 0.12);

    .card-funnel-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .junction-radios {
            width: auto;
            display: flex;
            align-items: center;
        }

        .title-card-summary {
            margin: 0;
            width: 35%;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 26px;
            text-transform: uppercase;
            color: getColor(text, dark);
        }
    
    }

    .card-funnel-body {
        display: flex;
        margin-top: 34px;

        .funnel {
            width: 35%;
            min-width: 290px;
            display: flex;
            padding-right: 23px;
            flex-direction: column;
            box-sizing: border-box;
            justify-content: space-between;

            .container-funnel-item {
                cursor: pointer;
                position: relative;
                border-radius: 4px;
                transition: all 0.2s ease-in-out;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-top: 89px solid getColor(background, light);

                &.item-active {
                    border-top: 89px solid getColor(text, dark, 0.15);
                }

                &:hover {
                    border-top: 89px solid getColor(background, medium);
                }

                &:nth-child(1) {
                    margin: 0 5p
                }

                &:nth-child(2) {
                    margin: 0 20px;
                }

                &:nth-child(3) {
                    margin: 0 37px;
                }

                &:nth-child(4) {
                    margin: 0 54px;
                }

                &:nth-child(5) {
                    margin: 0 71px;
                }

                &:nth-child(6) {
                    margin: 0 80px;
                }

                .funnel-item {
                    top: 0;
                    width: 100%;
                    z-index: 99;
                    display: flex;
                    line-height: 28px;
                    border-radius: 4px;
                    position: absolute;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    color: getColor(text, dark);
                    transform: translateY(-135%);
                    // border: 1px solid getColor(background, medium);

                    .item-name {
                        font-size: 14px;
                        text-align: center;
                        letter-spacing: 0.39px;
                        white-space: nowrap;
                    }

                    .item-value {
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 26px;
                    }

                    &:hover {
                        transition: all 0.3s ease-in-out;
                        // border: 1px solid getColor(text, dark);
                    }
                }
            }
        }

        .container-chart {
            width: 65%;
            padding: 25px;
            height: 485px;
            display: flex;
            border-radius: 4px;
            flex-direction: column;
            border: 1px solid #373536;
            justify-content: space-between;
            background-color: getColor(background, light);

            .chart-summary {
                div:nth-child(1) {
                    box-sizing: content-box;
                    height: 280px;

                    .highcharts-container {
                        height: 100%;
                    }
                }
            }

            .container-loading {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}