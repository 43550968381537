.options {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.objective-options {
  display: flex;
  justify-content: space-between;
  .checkbox-card {
      width: calc(50% - 12px);
  }
}
