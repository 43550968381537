@import "/src/common/styles/colors.scss";

.ant-tooltip-arrow {
    .ant-tooltip-arrow-content {
        background-color: getColor(text, dark);
    }
}
.ant-tooltip {
    .ant-tooltip-inner {
        font-size: 10px;
        max-width: 323px;
        line-height: 13px;
        letter-spacing: 0.33px;
        padding: 8px 6px !important; 
        background-color: getColor(text, dark);
    }
}