@import "/src/common/styles/colors.scss";

.summary-page {
    width: 100%;
    height: 100%;

    .summary-content {
        padding: 48px;
        margin: 0 auto;
        max-width: 1288px;
        overflow-x: hidden;
        box-sizing: content-box;

        .subtitle-summary {
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 26px;
            margin: 0 0 24px;
            text-transform: uppercase;
            color: getColor(text, dark);
        }

        .list-cards {
            display: grid;
            grid-gap: 16px;
            margin-bottom: 64px;
            grid-template-columns: repeat(4, 1fr);
        }

        .c-card-data {
            box-shadow: 0 2px 4px -1px rgba(168, 168, 168, 0.2), 0 1px 10px 0 rgba(204, 204, 204, 0.12);
        }
    }
}