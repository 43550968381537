@import "/src/common/styles/colors.scss";

.junction-header {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    .header-container,
    .subheader-container {
        width: 100%;
        height: 64px;
        display: flex;
        padding: 0 48px;
        overflow: hidden;
        align-items: center;
        border-bottom: 1px solid #d6d5d5;
        background-color: getColor(background, header);

        .header {
            width: 100%;
            display: flex;
            margin: 0 auto;
            max-width: 1288px;
            align-items: center;
            justify-content: space-between;

            .divider {
                height: 43px;
                width: 1px;
                opacity: 0.5;
                margin: 0 10px;
                background-color: #d7d3d3;
            }
            .title {
                margin: 0;
                font-size: 30px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 35px;
                color: getColor(text, dark);
            }

            .previous-page {
                cursor: pointer;
                font-size: 16px;
                line-height: 28px;
                text-align: center;
                letter-spacing: 0.44px;
                color: getColor(text, dark);
                transition: 0.2s all ease-in-out;
                .icon {
                    margin-right: 5px;
                }
                &:hover {
                    color: getColor(primary);
                }
            }

            .content-dropdown,
            .user {
                display: flex;
                align-items: center;
                justify-content: center;

                .circle-user {
                    width: 24px;
                    height: 24px;
                    display: flex;
                    font-size: 14px;
                    border-radius: 50%;
                    align-items: center;
                    justify-content: center;
                    color: getColor(text, white);
                    background-color: getColor(primary);
                }

                .icon {
                    font-size: 20px;

                    &::before {
                        color: getColor(text, dark);
                    }
                }

                .icon-down {
                    font-size: 16px;
                }

                .name-user {
                    font-size: 16px;
                    text-align: center;
                    padding: 0 6px;
                    letter-spacing: 0.44px;
                }

                .container-icon-notifications,
                .href-icon {
                    padding: 0px;
                    border-radius: 50%;
                    transition: 0.4s ease all;

                    i {
                        height: 20px;
                        width: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    &:hover {
                        transition: 0.4s ease all;
                        background-color: getColor(background, grey, 0.6);
                    }
                }

                .container-icon-notifications {
                    top: 0;
                    display: flex;
                    cursor: pointer;
                    margin-left: 10px;
                    position: relative;
                    align-items: center;
                    justify-content: center;

                    .circle {
                        top: 2px;
                        right: 2px;
                        width: 13px;
                        height: 13px;
                        display: flex;
                        font-size: 9px;
                        font-weight: bold;
                        border-radius: 50%;
                        position: absolute;
                        align-items: center;
                        justify-content: center;
                        background-color: red;
                        color: getColor(text, white);
                    }
                }
            }

            .ant-btn {
                padding: 0 !important;
                box-sizing: content-box;
                transition: 0.4s ease-in-out all;

                .content-dropdown {
                    .icon {
                        width: auto;
                        height: auto;

                        &:hover {
                            background-color: transparent;
                        }
                    }
                }

                &:hover {
                    opacity: 0.6;
                }
            }
        }
    }

    .subheader-container {
        height: 88px;
        background-color: getColor(background, header, 0.8);

        .subheader-right {
            .ant-picker {
                width: 0;
                opacity: 0;
                padding: 0;
                height: 40px;
            }

            .input-date {
                width: 270px;
                height: 40px;
                outline: none;
                display: flex;
                cursor: pointer;
                font-size: 14px;
                padding: 6px 12px;
                border-radius: 4px;
                align-items: center;
                justify-content: space-between;
                border: 1px solid getColor(background, medium) !important;
                background-color: getColor(background, lightest) !important;

                // text
                letter-spacing: 0.44px;
                color: getColor(text, dark);
                .icon-close2 {
                    font-size: 25px;
                }
            }
        }
    }
}
