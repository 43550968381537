@import "/src/common/styles/colors.scss";

.card-feed {
    width: 100%;
    display: flex;
    position: relative;
    max-width: 265px;
    border-radius: 4px;
    flex-direction: column;
    color: getColor(text, dark);
    border: 1px solid #eeecec;
    justify-content: space-between;

    .post-header {
        flex-wrap: wrap;
        display: flex;
        overflow: hidden;
        padding: 10px 15px;
        align-items: center;
        border-radius: 4px 4px 0 0;
        background-color: #eeecec;
        justify-content: space-between;

        &.header-right {
            justify-content: flex-end;
        }
        .influencer {
            flex-basis: 68%;
            width: 68%;
            display: flex;
            cursor: default;
            align-items: center;

            .name {
                width: 75%;
                font-size: 16px;
                color: #807f80;
                margin-left: 9px;
                line-height: 28px;
                overflow: hidden;
                white-space: nowrap;
                letter-spacing: 0.44px;
                text-overflow: ellipsis;
            }
        }

        .in-campaign {
            flex-basis: 12%;
            width: 12%;
            display: flex;
            align-items: center;

            .ant-spin {
                width: 24px;
                height: 24px;
            }

            .ant-switch {
                height: 8px;
                min-width: 24px;
                max-width: 24px;
                background-color: getColor(background, switch);

                &.ant-switch-checked {
                    background-color: getColor(primary);

                    .ant-switch-handle {
                        right: 0;
                        left: auto;
                    }
                }

                .ant-switch-handle {
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        .grow-or-decrease{
            flex-basis: 20%;
            width: 20%;
            flex-direction: column;
            align-items: center;
        }

    }

    .post-content {
        padding: 0 15px 15px;
        .icon-background {
            top: 0;
            width: 15px;
            height: 15px;
            margin-right: 5px;
            border-radius: 50%;
            // position: absolute;
        }
        .content-tiktok-icon {
            top: 50%;
            left: 50%;
            width: 60px;
            // opacity: 0.2;
            position: relative;
            transform: translate(-50%, -50%);
        }
        .nw-date {
            display: flex;
            padding: 4px 0;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 16px;
            align-items: center;
            justify-content: flex-start;

            // .icon {
            //     margin-right: 6px;
            // }
        }

        .container-post {
            width: 100%;
            height: 215px;
            position: relative;
            .content-icon {
                width: 100%;
                height: 100%;
                display: flex;
                font-size: 75px;
                align-items: center;
                justify-content: center;
                color: getColor(text, white);
            }
            .content-icon-background {
                top: 0;
                left: 5%;
                width: 90%;
                height: 100%;
                position: absolute;
                border-radius: 50%;

                .content-icon {
                    opacity: 0.2;
                }
            }
            .post-img {
                z-index: 1;
                width: 100%;
                height: 100%;
                cursor: pointer;
                position: relative;
                background-size: cover;
                background-repeat: no-repeat;

                .icon-link-external {
                    top: 0;
                    z-index: 9;
                    opacity: 0;
                    font-weight: bold;
                    position: absolute;
                    transition: 0.2s all ease-in-out;

                    &:hover {
                        opacity: 1;
                        transition: 0.2s all ease-in-out;
                        background-color: rgba(0, 0, 0, 0.2);
                    }
                }
            }
            .icon-play {
                top: 50%;
                width: 0;
                height: 0;
                left: 50%;
                z-index: 1;
                cursor: pointer;
                margin-right: -1px;
                position: absolute;
                transition: all 0.2s ease-in-out;
                transform: translate(-50%, -50%);
                border-top: 20px solid transparent;
                border-bottom: 20px solid transparent;
                border-left: 35px solid getColor(text, white);

                &:hover {
                    opacity: 0.8;
                }
            }
        }

        .interactions {
            height: 20px;
            display: flex;
            margin: 8px 0 8px;
            justify-content: space-between;

            .interaction {
                display: flex;
                font-size: 13px;
                cursor: default;
                letter-spacing: 0;
                line-height: 20px;
                align-items: center;
                text-transform: uppercase;

                .icon {
                    font-size: 14px;
                    width: 14px;
                    height: 14px;
                    margin-right: 2px;
                    text-align: right;
                }
                .icon-reach{
                    width: 14px;
                    height: 16px;
                }
                
                .icon-saved{
                    width: 16.8px;
                    height: 16.8px;
                }

                .icon-shares{
                    width: 16px;
                    height: 15px;
                }

                .icon-comments{
                    width: 18px;
                    height: 14px;
                }

                .icon-likes{
                    width: 17px;
                    height: 14px;
                }
                // .icon-likes, .icon-reach, .icon-shares{
                //     width: 10px !important;
                // }
            }
        }

        .description {
            margin: 0;
            cursor: text;
            font-size: 12px;
            min-height: 54px;
            overflow: hidden;
            letter-spacing: 0;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            background-color: getColor(background, lightest);
        }
    }
}
