@import "/src/common/styles/colors.scss";

.posts-modal {
    top: 0;
    padding: 0;
    max-width: 501px;
    position: relative;
    width: 50% !important;
    /*Reset para remover fundo branco*/
    border-radius: 4px;
    border-collapse: separate;

    .ant-modal-close-x,
    .ant-modal-close {
        display: none;
    }

    .ant-modal-content {
        border-radius: 4px;
    }

    .ant-modal-body {
        padding: 0;
    }

    .header-inf-name {
        height: 32px;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        border-radius: 4px 4px 0 0;
        color: getColor(text, white);
        background-color: getColor(primary);
    }

    .container-slider {
        position: relative;

        .icon {
            top: 0;
            width: 50px;
            height: 100%;
            z-index: 999;
            display: flex;
            font-size: 20px;
            cursor: pointer;
            position: absolute;
            font-weight: bold;
            align-items: center;
            transition: 0.2s all ease-in-out;

            &.icon-back1 {
                left: 10px;

                &.active:hover {
                    left: 5px;
                }
            }

            &.icon-advance1 {
                right: 10px;
                display: flex;
                justify-content: flex-end;

                &.active:hover {
                    right: 5px;
                }
            }

            &.disable {
                opacity: 0.2;
                cursor: default;
                pointer-events: none;
            }
        }
    }

    .posts-carousel {
        .slick-prev {
            left: 21px;
            z-index: 999999;
        }

        .slick-next {
            right: 21px;
            z-index: 999999;
        }

        // .slick-slide {
        //     width: 200px !important;
        // }
    }

    .posts-interactions {
        border-radius: 0 0 4px 4px;

        .interaction {
            margin: 0;
            height: 32px;
            display: flex;
            padding: 0 53px;
            align-items: center;
            justify-content: space-between;

            &:nth-child(odd) {
                background-color: getColor(selects, odd);
            }

            &:nth-child(even) {
                background-color: getColor(selects, even);
            }

            &:first-child {
                font-weight: bold;
                background-color: getColor(selects);
            }

            &:last-child {
                border-radius: 0 0 4px 4px;
            }
            .interaction-label {
                font-size: 14px;
                letter-spacing: 0;
                line-height: 19px;
                color: getColor(text, dark);
            }

            &.interaction-engagement,
            &.interaction-engajament_rate,
            &.interaction-reach_rate {
                .interaction-label,
                .interaction-value {
                    color: getColor(primary);
                }
            }
        }
    }

    .slick-track {
        height: 290px;

        .slick-slide {
            opacity: 0;
            align-items: center;
            display: flex !important;
            justify-content: center;
            transition: 0.2s all ease-in-out;

            &.slick-current {
                .img-clickable {
                    cursor: pointer;
                    &:hover {
                        transform: scale(1.15);
                    }
                }
            }

            .container-post {
                margin: 0 auto;
                position: relative;

                .post {
                    z-index: 2;
                    display: flex;
                    position: relative;
                    align-items: center;
                    justify-content: center;
                    transition: 0.2s all ease-in-out;

                    div {
                        width: 94px !important;
                        height: 170px;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: 0 0;
                        transition: 0.2s all ease-in-out;
                    }
                }

                .icon-background {
                    top: 50%;
                    left: 50%;
                    width: 90px;
                    height: 168px;
                    position: absolute;
                    transform: translate(-50%, -50%);
                    transition: 0.2s all ease-in-out;

                    .icon {
                        width: 100%;
                        height: 100%;
                        opacity: 0.2;
                        display: flex;
                        font-size: 50px;
                        align-items: center;
                        justify-content: center;
                        color: getColor(text, white);
                        transition: 0.2s all ease-in-out;
                    }

                    .tiktok-icon {
                        top: 50%;
                        left: 50%;
                        width: 75px;
                        opacity: 0.2;
                        position: relative;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }

        .slick-active {
            opacity: 1;

            .container-post .post img {
                width: 75%;
            }

            &.slick-center {
                .container-post {
                    .post .img {
                        width: 125px !important;
                        height: 223px;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: 0 0;
                    }

                    .icon-background {
                        width: 133px;
                        height: 242px;

                        .icon {
                            font-size: 75px;
                        }
                    }
                }
            }
        }

        .slick-center {
            .post {
                .img {
                    width: 100%;
                    height: 173px;
                    transform: scale(110%);
                }

                .icon-cplay {
                    top: 50%;
                    width: 0;
                    height: 0;
                    left: 50%;
                    z-index: 1;
                    cursor: pointer;
                    margin-right: -1px;
                    position: absolute;
                    transition: all 0.2s ease-in-out;
                    transform: translate(-50%, -50%);
                    border-top: 20px solid transparent;
                    border-bottom: 20px solid transparent;
                    border-left: 35px solid getColor(text, white);

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    .slick-arrow {
        &:before {
            color: getColor(text, dark) !important;
        }
    }

    .slick-prev {
        z-index: 9999;
        left: 21px !important;
    }

    .slick-next {
        right: 21px !important;
    }
}
