.hypomodal {
    .ant-modal-content {
        border-radius: 8px;
        width: 550px;
    }

    .approve-tiktok-modal-title {
        font-weight: 700;
        font-size: 16px;
        color: #4A4A4A;
    }

    .send-button {
        height: 40px;
        width: 100%;
    }
    .hypotextarea {
        min-height: 228px;
    }

    .button-container {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 13.2048px;

        .ant-spin {
            height: auto;
            margin-right: 10px;
            color: white;

            svg {
                fill: white;
            }
        }
    }

}
