@import "/src/common/styles/colors.scss";

.campaign-posts {
    .junction-header {
        color: getColor(text, dark);

        .subheader-left {
            .subheader-title {
                display: flex;
                font-size: 30px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 39px;
                overflow: hidden;
                align-items: center;
                text-transform: capitalize;

                .ant-btn {
                    margin-left: 12px;
                    &:hover {
                        background-color: transparent !important;
                    }

                    .icon-more {
                        padding: 8px;
                        border-radius: 50%;
                        font-weight: bold;
                        transition: 0.2s all ease-in-out;

                        &.icon-more-open {
                            transform: rotate(90deg);
                            background-color: getColor(background, grey);
                        }
                    }
                }
            }

            .text {
                font-size: 14px;
                line-height: 28px;
                color: #807F80;
                letter-spacing: 0.44px;
            }
        }

        .subheader-right {
            display: flex;
            align-items: center;

            .icon {
                margin-left: 8px;
            }
        }
    }

    .scroll-content {
        height: calc(100vh - 64px - 88px); // - header - subheader 

        .campaign-post-content {
            padding: 48px;
            margin: 0 auto;
            max-width: 1288px;
            overflow-x: hidden;
            box-sizing: content-box;

            .container-select {
                display: flex;
                justify-content: flex-end;
            }

            .card {
                margin: 24px 0 56px;
                .container-loading {
                    height: 300px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .card-header {
                    display: flex;
                    align-items: center;
                    margin-bottom: 31px;
                    justify-content: space-between;

                    .campaign-title-data {
                        font-size: 20px;
                        line-height: 24px;
                        margin-bottom: 0;
                        letter-spacing: 0.15px;
                    }

                    .campaign-subtitle-data {
                        font-size: 14px;
                        margin-bottom: 8px 0 0 !important;
                    }
                }
                .segment-filter-posts {
                    display: flex;
                    margin: 8px 0 32px;
                    position: relative;
                    transition: .5s all ease-in-out;
                    border-bottom: 1px solid getColor(background, medium);
            
                    .option-post {
                        width: 20%;
                        padding: 10px;
                        display: flex;
                        cursor: pointer;
                        align-items: center;
                        box-sizing: border-box;
                        justify-content: center;
                        color: red;
                        color: getColor(text, base);
            
                        &.active {
            
                            .text-option,
                            .text-value {
                                font-weight: 500;
                                color: getColor(primary);
                            }
            
                            &:hover {
                                opacity: 1;
                            }
                        }
            
                        .text-option {
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 21px;
                            letter-spacing: 0.44px;
                        }
            
                        &:hover {
                            opacity: 0.6;
                            transition: .2s all ease-in-out;
                        }
                    }
            
                    .segment-underline {
                        left: 0;
                        width: 20%;
                        height: 4px;
                        bottom: -2px;
                        position: absolute;
                        border-radius: 0 0 2px 2px;
                        transition: .2s all ease-in-out;
                        background-color: getColor(primary);
                    }
                    
                    &.segment-filter-tiktok {
                        .option-post {
                            &.active {
                                .text-option,
                                .text-value {
                                    font-weight: 500;
                                    color: getColor(socials, tiktok);
                                }
                            }
                        }
                        .segment-underline {
                            background-color: getColor(socials, tiktok);
                        }
                    }
                }
                .container-posts {
                    width: 100%;
                    display: grid;
                    grid-gap: 32px 8px;
                    grid-template-columns: repeat(4, 1fr);
                    .card-feed, .card-stories {
                        width: 100%;
                        margin: 0 auto;
                        overflow: hidden;
                    }
                    .empty-value {
                        grid-column: span 4;
                    }
                }
            }

            .simple-pagination {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
