.div-button-go {
    .connect-link-holder {
        margin-top: 15px;

        a {
            text-decoration: underline;
        }
    }
    .google-btn {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        min-width: 195px;
        max-width: 210px;
        height: 42px;
        background-color: #4285f4;
        border-radius: 2px;
        padding: 0;
        border: 0;
        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);

        .google-icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1px;
            margin-left: 1px;
            width: 40px;
            height: 40px;
            border-radius: 2px;
            background-color: white;
        }
        .google-icon {
            width: 18px;
            height: 18px;
        }
        .btn-text {
            float: right;
            margin: 12px 18px 0 0;
            color: white;
            font-size: 14px;
            letter-spacing: 0.2px;
            font-family: "Roboto";
        }
        &:hover {
            box-shadow: 0 0 6px #4285f4;
        }
        &:active {
            background: #1669f2;
        }
    }

    .ant-spin {
        margin-left: 8px;
        .ant-spin-dot-item {
            background-color: getColor(socials, google);
        }
    }
}
