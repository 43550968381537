.translate-modal {
    width: 70% !important;
    max-width: 450px !important;
    .ant-modal-content {
        border-radius: 4px;

        .ant-modal-header {
            display: none;
        }

        .ant-modal-footer {
            display: none;
        }

        .ant-modal-body {
            display: flex;
            padding: 24px;
            min-height: 100px;
            flex-direction: column;
            .title-translate-modal {
                font-size: 20px;
                margin-bottom: 40px;
            }
            .buttons {
                display: flex;
                justify-content: flex-end;
                .ant-btn {
                    margin-left: 16px;
                }
            }
        }
    }
}