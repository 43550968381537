@import "/src/common/styles/colors.scss";

.avatar {
    border-radius: 50%;
    display: inline-flex;
    transition: 0.2s ease-in all;
    border: 2px solid transparent;
    &.hover {
        cursor: pointer;
        &:hover {
            transition: 0.2s ease-in all;
            opacity: .8;
        }
    }

    &.avatar-google {border-color: getColor(socials, google);}
    &.avatar-twitter {border-color: getColor(socials, twitter);}
    &.avatar-youtube {border-color: getColor(socials, youtube);}
    &.avatar-facebook {border-color: getColor(socials, facebook);}
    &.avatar-instagram {border-color: getColor(socials, instagram);}
    &.avatar-pinterest {border-color: getColor(socials, pinterest);}
    &.avatar-tiktok {border-color: getColor(socials, tiktok);}
}