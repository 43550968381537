@import "/src/common/styles/colors.scss";

.search-tiktoker {
    .ant-form {
        display: flex;
        max-width: none;
        flex-wrap: wrap;
        justify-content: space-between;

        .ant-form-item {
            width: 15%;
            margin-bottom: 16px;

            .ant-select {
                width: 100% !important;
            }

            &.item-expirence,
            &.item-gender {
                width: 32%;
            }

            &.item-fast-growing {
                width: 32%;
                margin: 8px 0 0;

                .ant-form-item-control-input-content {
                    display: flex;
                    align-items: center;

                    .ant-switch {
                        background-color: #d9d9d9;
                        box-shadow: none !important;

                        .ant-switch-handle::before {
                            background-color: #9a9a9a;
                        }

                        &.ant-switch-checked {
                            background: #d2c9f1;

                            .ant-switch-handle::before {
                                background-color: #6a53bd;
                            }
                        }
                    }

                    span {
                        margin: 0 12px 0 8px;
                    }
                }
            }

            &.item-max-followers,
            &.item-max-avg-views {
                width: 0;
                height: 0;
                overflow: hidden;
            }
        }

        .item-space {
            width: 28%;
        }
    }

    .form-filters {
        display: flex;
        flex-wrap: wrap;
        margin: 24px 0 16px;
        align-items: center;
        padding-bottom: 24px;
        color: getColor(primary);
        border-bottom: 1px solid getColor(text, medium, 0.2);

        .item-filter-text {
            display: flex;
            padding: 4px 8px;
            border-radius: 8px;
            margin-right: 15px;
            align-items: center;
            background-color: getColor(primary, 0.23);

            .icon {
                margin-left: 8px;
                cursor: pointer;
                transition: all 0.2s ease-in-out;

                &:hover {
                    opacity: 0.6;
                }
            }
        }

        .item-filter-clear {
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover {
                opacity: 0.6;
            }
        }
    }

    .tiktok-influencers {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .subtitle,
        .ant-pagination {
            .subtitle-description {
                font-size: 14px;
                color: #888;
            }
            width: 49%;
        }

        .container-select .ant-select-selector {
            border: none !important;
            background-color: transparent !important;
        }

        .influencers {
            width: 100%;
            display: grid;
            grid-gap: 64px;
            margin-top: 32px;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .text-err {
        width: 100%;
        margin-top: 32px;
    }
}
