@import "/src/common/styles/colors.scss";

.ant-tabs-content-holder {
    .card {
        margin-bottom: 24px;

        .card-content {
            display: grid;
            grid-template-columns: 8fr 3fr;

            .card-title {
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 24px;
                color: getColor(text, dark);
            }

            .edit-container {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;

                &.edit-password {
                    flex-direction: column;
                    align-items: flex-start;

                    .password-requests {
                        margin-bottom: 31px;
                    }

                    .ant-form-item {
                        margin-bottom: 31px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .ant-form-item {
                    width: 100%;
                    margin-bottom: 0;
                    margin-right: 16px;

                    .input-container {
                        max-width: 540px;
                    }

                    .input-label {
                        height: 56px;
                        max-width: 540px;
                    }
                }
            }
        }

        .card-footer-aside {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            // 
            grid-column-start: 2;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 3;

            &.end {
                align-items: flex-end;

                button {
                    margin-left: 16px;
                }
            }
        }
    }

    .ant-form {
        width: 100%;
        margin: none !important;
        max-width: none !important;

        .ant-form-item-label>label {
            height: auto !important;
        }
    }
}