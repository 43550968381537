@import "/src/common/styles/colors.scss";

.login-page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: getColor(primary);

    .use-google {
        margin-top: 15px;
        width: 100%;
        height: 50px;
        display: flex;
        cursor: pointer;
        padding: 0 12px;
        align-items: center;
        justify-content: space-evenly;
        transition: all 0.2s ease-in-out;
        background-color: getColor(background, lightest) !important;
        border: 1px solid getColor(background, lightGrey) !important;
    }

    .why-ga {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-right: 24px;
        margin-top: 5px;
        margin-bottom: 32px;
    }

    .logo-hypolake {
        z-index: 1;
        width: 160px;
    }

    .login-container {
        width: 80%;
        z-index: 999;
        display: flex;
        max-width: 400px;
        border-radius: 4px;
        padding: 32px 0 0;
        align-items: center;
        flex-direction: column;
        background-color: getColor(background, lightest);

        .login-title {
            margin: 0;
            font-size: 24px;
            text-align: center;
            text-transform: uppercase;
        }

        .ant-form {
            width: 100%;
            display: flex;
            overflow: hidden;
            max-height: 410px;
            align-items: center;
            flex-direction: column;
            padding: 16px 24px 16px;
            justify-content: space-between;
            transition: all 0.2s ease-in-out;

            .ant-form-item {
                width: 100%;
                position: relative;
                height: 50px !important;
                margin: 32px 0 0 0 !important;
                transition: all 0.2s ease-in-out;

                &.animate {
                    animation: fadein 2s;
                    -o-animation: fadein 2s;
                    -ms-animation: fadein 2s;
                    -moz-animation: fadein 2s;
                    -webkit-animation: fadein 2s;

                    @keyframes fadein {
                        from {
                            opacity: 0;
                        }

                        to {
                            opacity: 1;
                        }
                    }

                    /* Firefox < 16 */
                    @-moz-keyframes fadein {
                        from {
                            opacity: 0;
                        }

                        to {
                            opacity: 1;
                        }
                    }

                    /* Safari, Chrome and Opera > 12.1 */
                    @-webkit-keyframes fadein {
                        from {
                            opacity: 0;
                        }

                        to {
                            opacity: 1;
                        }
                    }

                    /* Internet Explorer */
                    @-ms-keyframes fadein {
                        from {
                            opacity: 0;
                        }

                        to {
                            opacity: 1;
                        }
                    }

                    /* Opera < 12.1 */
                    @-o-keyframes fadein {
                        from {
                            opacity: 0;
                        }

                        to {
                            opacity: 1;
                        }
                    }
                }

                .ant-form-item-explain {
                    top: 100%;
                    width: 65%;
                    font-size: 11px;
                    position: absolute;
                }

                .input-label-junction .input-label {
                    height: 50px !important;
                }
            }

            .junction-password {
                width: 100%;
                position: relative;

                .forgot-password {
                    right: 0;
                    margin: 0;
                    width: auto;
                    border: none;
                    bottom: -20px;
                    outline: none;
                    cursor: pointer;
                    font-size: 12px;
                    position: absolute;
                    background-color: transparent;
                    transition: all 0.2s ease-in-out;

                    &:hover {
                        color: getColor(primary);
                    }
                }
            }

            &.forgot {
                max-height: 300px;
                transition: all 0.2s ease-in-out;
            }
        }


        .forgot-password {
            width: 100%;
            font-size: 12px;
            cursor: pointer;
            text-align: right;
            padding: 2px 0 0 0;
            transition: all 0.2s ease-in-out;

            &:hover {
                color: getColor(primary);
            }
        }

        .button-login {
            height: 50px;
            margin: 74px 0 0;
            text-transform: uppercase;
        }

        .google-login {
            padding: 0 26px 0px;
        }

        .button-register {
            width: 100%;
            border: none;
            cursor: pointer;
            font-weight: bold;
            height: 50px !important;
            text-transform: uppercase;
            border-radius: 0 0 4px 4px;
            color: getColor(text, white);
            transition: all 0.2s ease-in-out;
            background-color: getColor(secondary);

            &:hover {
                opacity: 0.6;
            }
        }
    }
}