@import "/src/common/styles/colors.scss";

.card-post-video-simple {
    width: 100%;
    display: flex;
    overflow: hidden;
    border-radius: 4px;
    position: relative;
    padding: 13px 11px;
    flex-direction: column;
    color: getColor(text, dark);
    justify-content: space-between;
    border: 1px solid getColor(background, grey);

    .container-post {
        height: 302px;
        position: relative;

        .icon-background {
            top: 0;
            width: 100%;
            height: 100%;
            position: absolute;

            .icon {
                width: 100%;
                opacity: .2;
                height: 100%;
                display: flex;
                font-size: 75px;
                align-items: center;
                justify-content: center;
                color: getColor(text, white);
            }

            .tiktok-icon {
                top: 50%;
                left: 50%;
                width: 75px;
                opacity: .2;
                position: relative;
                transform: translate(-50%, -50%);
            }
        }

        .post-content {
            z-index: 2;
            height: 100%;
            position: relative;

            .post-video {
                width: 100%;

                div {
                    width: 100%;
                }
            }

            .post-img {
                height: 100%;
                cursor: pointer;
                background-size: 100% 100%;
                border-radius: 0 0 4px 4px;
                background-position: center;
                background-repeat: no-repeat;
            }

            &:hover {
                .container-play {
                    .play {
                        display: none;
                    }

                    .icon {
                        display: block;
                    }
                }
            }

            .container-play {
                top: 50%;
                left: 50%;
                z-index: 1;
                width: 50px;
                height: 50px;
                display: flex;
                cursor: pointer;
                border-radius: 50%;
                position: absolute;
                align-items: center;
                justify-content: center;
                transition: all .2s ease-in-out;
                transform: translate(-50%, -50%);
                background-color: rgba(0, 0, 0, 0.52);

                .play {
                    width: 0;
                    height: 0;
                    margin-top: 1px;
                    margin-right: -4px;
                    border-top: 12px solid transparent;
                    border-bottom: 12px solid transparent;
                    border-left: 20px solid getColor(text, white);
                }

                .icon {
                    display: none;
                    font-size: 24px;

                    &::before {
                        color: getColor(text, white) !important;
                    }
                }
            }
        }
    }

    .post-footer {
        bottom: 0;
        z-index: 2;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-top: 8px;
        align-items: center;
        border-radius: 0 0 4px 4px;
        justify-content: space-between;
        background-color: getColor(background, lightest);

        .junction {
            width: 49%;
            display: flex;
            font-size: 15px;
            cursor: default;
            margin-top: 8px;
            letter-spacing: 0;
            line-height: 20px;
            align-items: center;
            justify-content: center;
            color: getColor(primary);

            .icon {
                margin-right: 5px;
                font-weight: bold;
                font-size: 18px;
            }
        }
    }
}