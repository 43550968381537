@import "/src/common/styles/colors.scss";

.container-add-influencer {
    .junction-input-button {
        display: flex;
        justify-content: flex-start;
        .input-container {
            margin-right: 16px;
        }
    }

    .text-orientations {
        display: flex;
        margin: 24px 0;
        font-size: 14px;
        text-align: left;
        line-height: 20px;
        letter-spacing: 0.22px;
        flex-direction: column;
        .title-orientations {
            font-weight: 600;
        }
        .subtitle-orientations {
            margin: 3px 0 10px;
        }
    }
}