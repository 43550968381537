@import "/src/common/styles/colors.scss";

.card-content {
    .integraion-go {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.22px;
        margin-top: 22px;

        display: grid;
        // grid-gap: 10px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
            "step-name  step-name  step-name"
            "text text description";

        .step-name {
            grid-area: step-name;
            margin-bottom: 7px !important;
            display: flex;

            a {
                margin-left: 17px;
                font-size: 12px;
            }
        }

        .text {
            grid-area: text;
        }

        .description {
            margin-left: 20px;
            padding-left: 10px;
            grid-area: description;
            border-left: 1px solid getColor(background, grey);
        }
    }

    .text-empty {
        margin: 20px 0 0;
    }

    .card-user-go {
        display: flex;
        margin-top: 7px;
    }

    .p-change-account {
        cursor: pointer;
        font-size: 16px;
        color: #6a53bd;
        margin: 8px 0 0;
        transition: all 0.2s ease-in-out;
        &:hover {
            color: getColor(primary);
        }
    }
}

@import url(https://fonts.googleapis.com/css?family=Roboto:500);
