.container-three-charts {
    display: flex;
    height: 400px;
    div {
        width: 70%;
    }
    .one-chart {
        width: 100% !important;
        border-right: 1px solid #ccc;
    }
    .aside-three-charts {
        width: 30%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div {
            height: 50%;
            width: 100% !important;
            .highcharts-container {
                height: 100% !important;
                svg {
                    height: 100%;
                }
            }
        }
        .line-division {
            width: 100%;
            height: 1px;
            background-color: #ccc;
        }
    }
}