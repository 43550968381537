@import "/src/common/styles/colors.scss";

.campaign-influencer {
    .junction-header {
        color: getColor(text, dark);

        .subheader-left {
            display: flex;
            align-items: center;
            .subheader-junction {
                display: flex;
                margin-left: 7px;
                flex-direction: column;
                justify-content: center;
                color: getColor(text, dark);
                .subheader-inf {
                    height: 32px;
                    display: flex;
                    align-items: center;
                    .influencer-name {
                        font-size: 30px;
                        font-weight: 500;
                        letter-spacing: 0;
                    }
                    .icon {
                        cursor: pointer;
                        font-size: 20px;
                        margin-left: 7px;
                        transition: .2s all ease-in-out;
                        &:hover {
                            opacity: .6;
                        }
                    }
                }

                .subheader-cmp {
                    display: flex;
                    font-size: 16px;
                    overflow: hidden;
                    letter-spacing: 0;
                    line-height: 21px;
                    align-items: center;
                    text-transform: capitalize;

                    .ant-btn {
                        margin-left: 12px;
                        &:hover {
                            background-color: transparent !important;
                        }

                        .icon-more {
                            padding: 8px;
                            border-radius: 50%;
                            font-weight: bold;
                            transition: 0.2s all ease-in-out;

                            &.icon-more-open {
                                transform: rotate(90deg);
                                background-color: getColor(background, grey);
                            }
                        }
                    }
                }
            }
        }

        .subheader-right {
            display: flex;
            align-items: center;

            .icon {
                margin-left: 8px;
            }
        }
    }

    .scroll-content {
        height: calc(100vh - 64px - 88px); // - header - subheader 

        .campaign-title-data {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 31px;
            letter-spacing: 0.15px;
            color: getColor(text, dark);
        }
        
        .campaign-influencer-content {
            padding: 48px;
            margin: 0 auto;
            max-width: 1288px;
            overflow-x: hidden;
            box-sizing: content-box;

            .container-select {
                display: flex;
                justify-content: flex-end;
            }

            .card {
                margin: 24px 0 56px;

                .card-header {
                    display: flex;
                    align-items: center;
                    margin-bottom: 31px;
                    justify-content: space-between;
                    
                    .campaign-title-data {
                        font-size: 20px;
                        line-height: 24px;
                        margin-bottom: 0;
                        letter-spacing: 0.15px;
                        color: #4A4A4A !important;
                    }
                    
                    .campaign-subtitle-data {
                        font-size: 14px;
                        margin: 8px 0 0 !important;
                        color: #4A4A4A !important;
                    }
                }

                .segment-filter-posts {
                    display: flex;
                    margin: 8px 0;
                    position: relative;
                    transition: .5s all ease-in-out;
                    border-bottom: 1px solid getColor(background, medium);

                    .option-post {
                        width: 20%;
                        padding: 10px;
                        display: flex;
                        cursor: pointer;
                        align-items: center;
                        box-sizing: border-box;
                        justify-content: center;

                        &.active {

                            .text-option,
                            .text-value {
                                font-weight: 500;
                            }

                            &:hover {
                                opacity: 1;
                            }
                        }

                        .text-option {
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 21px;
                            letter-spacing: 0.44px;
                        }

                        &:hover {
                            opacity: 0.6;
                            transition: .2s all ease-in-out;
                        }
                    }

                    .segment-underline {
                        left: 0;
                        width: 20%;
                        height: 4px;
                        bottom: -2px;
                        position: absolute;
                        border-radius: 0 0 2px 2px;
                        transition: .2s all ease-in-out;
                    }
                }

                .container-loading {
                    height: 250px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                
                .container-posts {
                    width: 100%;
                    display: grid;
                    margin-top: 32px;
                    grid-gap: 32px 8px;
                    grid-template-columns: repeat(4, 1fr);
                    .empty-value {
                        grid-column: span 4;
                    }
                    .card-feed,
                    .card-post-video,
                    .card-stories {
                        width: 100%;
                        margin: 0 auto;
                        overflow: hidden;
                    }
                }
            }

            .simple-pagination {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}