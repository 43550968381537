@import "/src/common/styles/colors.scss";

.drop-down {
    height: 40px;
    display: flex;
    font-size: 18px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    border: none !important;
    box-shadow: none !important;
}

.ant-dropdown {
    ul li {
        font-size: 16px;
        padding: 10px 21px;
        color: getColor(text, medium);
        &:hover {
            color: getColor(primary) !important;
            background-color: getColor(background, light) !important;
        }
        i {
            margin-right: 8px;
        }
    }
    &:after {
        box-shadow: none !important;
    }
}