@import "/src/common/styles/colors.scss";

.card-tiktoker {
    display: flex;
    border-radius: 4px;
    padding: 16px;
    flex-direction: column;
    cursor: pointer !important;
    border: 1px solid #D6D5D5;

    .avatar-name {
        display: flex;

        .avatar {
            margin-right: 10px;
            position: relative;

            .ant-avatar {
                width: 46px !important;
                height: 46px !important;
            }

            .icon {
                right: 10px;
                bottom: 1px;
                font-size: 15px;
                border-radius: 50%;
                position: absolute;
                color: getColor(primary);
                background-color: white;
            }
        }

        .name-location {
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .name {
                font-size: 18px;
                font-weight: 400;
            }

            .location {
                font-size: 10px;
                font-weight: 400;
                color: getColor(text, medium);
            }
        }
    }

    .bio {
        cursor: pointer;
        font-size: 10px;
        min-height: 54px;
        overflow: hidden;
        letter-spacing: 0;
        font-weight: 400;
        line-height: 18px;
        margin: 16px 0 24px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        background-color: getColor(background, lightest);
    }

    .interactions {
        display: flex;
        flex-wrap: wrap;
        margin-top: 24px;
        margin-bottom: 16px;
        justify-content: flex-start;

        .interaction {
            margin-right: 16px;
            span {
                display: flex;
                align-items: center;
                margin-bottom: 16px;

                .icon {
                    bottom: -1px;
                    font-size: 18px;
                    margin-right: 6px;
                    position: relative;
                    color: getColor(primary);
                }
            }
        }
    }

    .ant-btn {
        width: 100%;
        overflow: hidden;

        span {
            font-size: 14px;
        }

        &:hover {
            opacity: 1 !important;
            background-color: getColor(secondary);
            color: getColor(text, light) !important;
        }
    }
}