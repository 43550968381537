@import "/src/common/styles/colors.scss";

.chart-card {
    color: getColor(text, dark);
    border-left: 8px solid white;
    .container-loading {
        display: flex;
        height: 538px;
        align-items: center;
        img {
            top: -30px;
            position: relative;
            width: 150px !important;
        }
    }

    &.chart-card-tiktok {
        border-left: 8px solid getColor(socials, tiktok);
    }

    &.chart-ec {
        // border-left: 8px solid #4285F4;

        .container-segment-chart {
            margin-bottom: 80px;

            .segment-chart {
                .chart-option {
                    display: flex;
                    justify-content: space-between;
                    &.active {
                        color: #4285f4;
                    }
                }

                .segment-underline {
                    background-color: #4285f4;
                }
            }
        }
    }

    &.contractor-chart {
        .card-content {
            .chart-header {
                flex-direction: column;
                align-items: flex-start;

                .campaign-subtitle-data {
                    display: flex;
                    align-items: center;

                    .value,
                    .style-value {
                        padding: 0 5px;
                        font-size: 15px;
                        font-weight: bold;
                        transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
                    }

                    .style-value {
                        padding: 0;
                    }
                }
            }

            .chart-footer {
                display: flex;
                font-size: 12px;
                font-weight: 300;
                line-height: 16px;
                letter-spacing: 0.4px;
                justify-content: flex-end;
            }
        }
    }

    .chart-header {
        display: flex;
        align-items: center;
        margin-bottom: 29px;
        justify-content: space-between;

        .chart-titles {
            display: flex;
            flex-direction: column;

            .chart-title {
                margin: 0 !important;
            }

            .chart-subtitle {
                margin: 8px 0 0 !important;
            }
        }
    }

    .segment-type-posts {
        display: flex;
        margin-top: 8px;
        position: relative;
        transition: 0.5s all ease-in-out;
        border-bottom: 1px solid getColor(background, medium);

        .option-post {
            width: 115px;
            padding: 10px;
            display: flex;
            cursor: pointer;
            align-items: center;
            box-sizing: border-box;
            justify-content: center;

            &.active {
                .text-option,
                .text-value {
                    font-weight: 500;
                }

                &:hover {
                    opacity: 1;
                }
            }

            &.color-all {
                color: getColor(primary);
            }

            .icon {
                font-size: 16px;
                margin-right: 7px;
                margin-bottom: -2px;

                &.icon-general-posts2 {
                    margin-bottom: -4px;
                }

                &.icon-feed2 {
                    font-size: 18px;
                }

                &.icon-stories2 {
                    font-size: 18px;
                }
            }

            .text-option {
                font-size: 16px;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0.44px;
            }

            &:hover {
                opacity: 0.6;
                transition: 0.2s all ease-in-out;
            }
        }

        .segment-underline {
            left: 0;
            width: 115px;
            height: 4px;
            bottom: -2px;
            max-width: 225px;
            position: absolute;
            border-radius: 0 0 2px 2px;
            transition: 0.2s all ease-in-out;
            background-color: getColor(primary);
        }
    }

    .container-segment-chart {
        width: 100%;
        margin: 25px 0 0;
        border-bottom: 1px solid getColor(background, medium);

        .segment-chart {
            display: flex;
            position: relative;
            transition: 0.5s all ease-in-out;

            &.chart-hash {
                width: 70%;
            }

            .chart-option {
                padding: 10px;
                display: flex;
                cursor: pointer;
                flex-direction: column;
                box-sizing: border-box;
                align-items: flex-start;
                justify-content: space-between;

                &.active {
                    .text-option,
                    .text-value {
                        font-weight: 500;
                    }

                    &:hover {
                        opacity: 1;
                    }
                }

                &.color-all {
                    color: getColor(primary);
                }

                .icon {
                    font-size: 16px;
                    margin-right: 7px;
                    margin-bottom: -2px;

                    &.icon-general-posts2 {
                        margin-bottom: -4px;
                    }

                    &.icon-feed2 {
                        font-size: 18px;
                    }

                    &.icon-stories2 {
                        font-size: 18px;
                    }
                }

                .text-option {
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 7px;
                    letter-spacing: 0.44px;
                }

                .text-value {
                    margin: 0;
                    width: 100%;
                    font-size: 20px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 26px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                &:hover {
                    opacity: 0.6;
                    transition: 0.2s all ease-in-out;
                }
            }

            .segment-underline {
                left: 0;
                height: 4px;
                bottom: -2px;
                position: absolute;
                border-radius: 0 0 2px 2px;
                transition: 0.2s all ease-in-out;
                background-color: getColor(primary);
            }
        }
    }

    .chart-select-filter {
        height: 40px;
        margin: 20px 0 10px;

        .ant-select-selector {
            opacity: 1;
            animation: fade 0.2s linear;

            @keyframes fade {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }
        }
    }
    .empty-text-value {
        width: 100%;
        height: 150px;
        display: flex;
        font-size: 18px;
        align-items: center;
        justify-content: center;
        color: getColor(text, dark);
    }
}

.apexcharts-tooltip {
    margin-top: -20px;
    transform: translate(-36%);
}

.tspan {
    font-size: 10px;
}

.tooltip-chart {
    height: 30px;
    display: flex;
    padding: 0 5px;
    min-width: 124px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px 0 rgba(201, 201, 201, 0.5);

    .tooltip-text {
        font-size: 12px;
        letter-spacing: 0;
        color: getColor(text, dark);

        b {
            margin-left: 5px;
        }
    }
}

.apexcharts-legend-default-overflow {
    overflow: unset !important;
}

.apexcharts-legend-series {
    margin: 0 20px !important;

    .apexcharts-legend-marker {
        margin-right: 5px;
        border-radius: 50% !important;
    }
}

.apexcharts-xcrosshairs.apexcharts-active,
.apexcharts-ycrosshairs.apexcharts-active {
    opacity: 0 !important;
}

.apexcharts-gridlines-horizontal {
    overflow: hidden !important;
}

.ant-spin {
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.counter-basic-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;

    .counter-basic-title {
        font-size: 16px;
        color: getColor(text, mediumGray);
    }
    .counter-basic-value {
        font-size: 20px;
        color: getColor(text, dark);
        line-height: 26px;
    }
}

.border-instagram-color {
    border-left: 8px solid #a032ac;
}

.border-tiktok-color {
    border-left: 8px solid black;
}

.font-instagram-color {
    color: #a032ac;
}

.font-tiktok-color {
    color: black;
}

.chart-container {
    .missing-analytics-connect {
        min-height: 250px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .div-button-go {
            margin-top: 20px;
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
        }
    }

    .select-goals-analytics-connect {
        min-height: 250px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.15px;
        font-family: Montserrat, sans-serif;

        h3 {
            color: #3db483;
            font-weight: bold;
            margin-bottom: 0;
        }

        p {
            font-size: 12px;
        }

        button {
            margin-top: 20px;
            background-color: #3db483;
            color: #fff;
            border-radius: 5px;
            border: 0px;
            padding: 5px 20px;
            font-weight: bold;
        }
    }
}
