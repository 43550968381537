.double-bar {
    .apexcharts-series:nth-child(odd) {
        opacity: 0.4;
    }
    .highcharts-legend {
        margin-top: 20px;
    }
    .highcharts-credits {
        display: none;
    }
}