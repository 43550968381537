@import "/src/common/styles/colors.scss";

.contract-page {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    position: absolute;
    z-index: 9999999999;
    align-items: center;
    justify-content: center;
    background-color: #313231fb;


    .smoke-img {
        position: absolute;

        &.left {
            top: 0;
            left: 0;
            width: 55%;
        }

        &.top {
            display: none;
            top: 0;
            transform: rotate(90deg);
        }

        &.right {
            right: 0;
            bottom: 0;
            width: 55%;
        }

        &.bottom {
            display: none;
            z-index: 9999999999999999;
            bottom: 0;
        }
    }

    .container-contract {
        width: 85%;
        height: 90vh;
        z-index: 9999;
        display: flex;
        overflow: hidden;
        padding: 26px 14px;
        text-align: center;
        position: relative;
        border-radius: 8px;
        align-items: center;
        box-sizing: border-box;
        flex-direction: column;
        background-color: #F5F5F5;
        justify-content: space-between;

        .contract-title {
            display: flex;
            font-size: 16px;
            font-weight: bold;
            line-height: 24px;
            align-items: center;
            margin-bottom: 16px;
            flex-direction: column;
            text-transform: uppercase;
        }

        .contract-text {
            height: 80%;
            font-size: 12px;
            overflow-y: auto;
            line-height: 24px;
            margin-bottom: 0;
            padding-right: 20px;
            text-align: justify;
            letter-spacing: 0.15px;
            color: getColor(text, dark);
        }

        .checkbox-container {
            margin: 20px auto 20px 0;

            .checkbox-text {
                font-size: 12px;
            }
        }

        .button-submit {
            width: 100%;
            height: 52px;
            border: none;
            outline: none;
            margin: 0 auto;
            cursor: pointer;
            font-weight: 600;
            max-width: 280px;
            line-height: 24px;
            border-radius: 4px;
            text-transform: uppercase;
            color: getColor(text, white);
            background: getColor(secondary);
            transition: all .2s ease-in-out;
            box-shadow: 0 2px 4px -1px rgba(168, 168, 168, 0.2), 0 1px 10px 0 rgba(204, 204, 204, 0.12);

            &:hover {
                opacity: 0.7;
            }

            .text-button {
                font-size: 16px;
            }
        }
    }

    @media (min-width: 834px) {
        .container-contract {
            padding: 45px 90px;

            .icon-close {
                top: 25px;
                right: 15px;
                font-size: 30px;
                padding: 15px 24px;
            }
        }
    }

    @media (min-width: 1440px) {
        .container-contract {
            display: flex;
            padding: 85px 98px;
            flex-direction: column;

            .icon-close {
                width: 100%;
                display: flex;
                max-width: 1440px;
                position: relative;
                margin: 0 auto -20px;
                justify-content: flex-end;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .contract-page {
        .container-contract {
            width: 85%;
            height: 80vh;
            padding: 32px;
            border-radius: 8px;
            background-color: #F5F5F5;

            .contract-title {
                font-size: 20px;
                margin-bottom: 26px;
            }

            .contract-text {
                height: 80%;
                font-size: 14px;
            }

            .checkbox-container {
                margin: 40px auto 32px 0;

                .checkbox-text {
                    font-size: 14px;
                }
            }
        }
    }
}

@media (min-width: 834px) {
    .container-contract {
        padding: 45px 90px;

        .icon-close {
            top: 25px;
            right: 15px;
            font-size: 30px;
            padding: 15px 24px;
        }
    }
}

@media screen and (min-width: 1024px) {
    .contract-page {
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        position: absolute;
        z-index: 9999999999;
        align-items: center;
        justify-content: center;
        // background-color: rgba(0, 0, 0, 0.8);
        background-color: #313231;


        .smoke-img {
            position: absolute;

            &.left {
                top: 0;
                left: 0;
                width: 25%;
            }

            &.top {
                display: none;
                top: 0;
                transform: rotate(90deg);
            }

            &.right {
                right: 0;
                bottom: 0;
                width: 25%;
            }

            &.bottom {
                display: none;
                z-index: 9999999999999999;
                bottom: 0;
            }
        }

        .container-contract {
            width: 85%;
            height: 85vh;
            z-index: 9999;
            display: flex;
            overflow: hidden;
            padding: 30px 48px;
            text-align: center;
            position: relative;
            border-radius: 15px;
            align-items: center;
            box-sizing: border-box;
            flex-direction: column;
            background-color: #F5F5F5;
            justify-content: space-between;

            .contract-title {
                display: flex;
                font-size: 30px;
                font-weight: bold;
                line-height: 36px;
                align-items: center;
                margin-bottom: 16px;
                flex-direction: column;
                text-transform: uppercase;
            }

            .contract-text {
                height: 80%;
                font-size: 16px;
                overflow-y: auto;
                line-height: 24px;
                margin-bottom: 0;
                padding-right: 20px;
                text-align: justify;
                letter-spacing: 0.15px;
                color: getColor(text, dark);
            }

            .checkbox-container {
                margin: 16px auto 27px 0;
            }

            .button-submit {
                width: 100%;
                height: 46px;
                border: none;
                outline: none;
                margin: 0 auto;
                cursor: pointer;
                font-size: 18px;
                font-weight: 600;
                max-width: 384px;
                line-height: 24px;
                border-radius: 4px;
                text-transform: uppercase;
                color: getColor(text, white);
                background: getColor(secondary);
                transition: all .2s ease-in-out;
                box-shadow: 0 2px 4px -1px rgba(168, 168, 168, 0.2), 0 1px 10px 0 rgba(204, 204, 204, 0.12);

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}

@media (min-width: 1440px) {
    .container-contract {
        display: flex;
        padding: 85px 98px;
        flex-direction: column;

        .icon-close {
            width: 100%;
            display: flex;
            max-width: 1440px;
            position: relative;
            margin: 0 auto -20px;
            justify-content: flex-end;
        }
    }
}
