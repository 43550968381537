@import "/src/common/styles/colors.scss";

.rp-page {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 20px 0;
    overflow-y: auto;
    position: relative;
    align-items: center;
    flex-direction: column;
    background-color: getColor(primary);

    .background-themes {
        top: 0;
        width: 100%;
        opacity: 0.2;
        height: 100%;
        position: fixed;
        background-size: 20%;
        background-image: url("../../common/assets/images/image-background-themes.png");
    }

    .rp-container {
        width: 80%;
        z-index: 999;
        display: flex;
        max-width: 400px;
        padding: 24px 0 0;
        border-radius: 4px;
        align-items: center;
        flex-direction: column;
        background-color: getColor(background, lightest);

        .logo-hypolake {
            z-index: 1;
            width: 55px;
            cursor: pointer;
            margin-bottom: 16px;
            transition: all 0.2s ease-in-out;
            &:hover {
                opacity: 0.6;
            }
        }

        .ant-form {
            width: 100%;
            height: 100%;
            padding: 0 24px;
            overflow: hidden;
            align-items: center;
            transition: all 0.2s ease-in-out;

            .ant-form-item {
                width: 100%;
                position: relative;
                height: 50px !important;
                margin: 24px 0 0 0 !important;
                transition: all 0.2s ease-in-out;

                &.animate {
                    animation: fadein 2s;
                    -o-animation: fadein 2s;
                    -ms-animation: fadein 2s;
                    -moz-animation: fadein 2s;
                    -webkit-animation: fadein 2s;

                    @keyframes fadein {
                        from {
                            opacity: 0;
                        }

                        to {
                            opacity: 1;
                        }
                    }

                    /* Firefox < 16 */
                    @-moz-keyframes fadein {
                        from {
                            opacity: 0;
                        }

                        to {
                            opacity: 1;
                        }
                    }

                    /* Safari, Chrome and Opera > 12.1 */
                    @-webkit-keyframes fadein {
                        from {
                            opacity: 0;
                        }

                        to {
                            opacity: 1;
                        }
                    }

                    /* Internet Explorer */
                    @-ms-keyframes fadein {
                        from {
                            opacity: 0;
                        }

                        to {
                            opacity: 1;
                        }
                    }

                    /* Opera < 12.1 */
                    @-o-keyframes fadein {
                        from {
                            opacity: 0;
                        }

                        to {
                            opacity: 1;
                        }
                    }
                }

                .ant-form-item-label {
                    padding: 0 0 4px;
                }

                .ant-form-item-explain {
                    top: 100%;
                    width: 70%;
                    font-size: 11px;
                    position: absolute;
                }

                .input-label-junction .input-label {
                    height: 50px !important;
                }
            }

            .junction-password {
                width: 100%;
                position: relative;

                .forgot-password {
                    right: 0;
                    top: 100%;
                    margin: 0;
                    width: auto;
                    border: none;
                    bottom: -23px;
                    outline: none;
                    cursor: pointer;
                    font-size: 12px;
                    position: absolute;
                    background-color: transparent;
                    transition: all 0.2s ease-in-out;

                    &:hover {
                        color: getColor(primary);
                    }
                }
            }
        }

        .forgot-password {
            width: 100%;
            font-size: 12px;
            cursor: pointer;
            text-align: right;
            padding: 2px 0 0 0;
            transition: all 0.2s ease-in-out;

            &:hover {
                color: getColor(primary);
            }
        }

        .password-requests {
            font-size: 18px;
            text-align: left;
            margin: 26px 0 0;
            line-height: 24.5px;
            letter-spacing: 0.2px;

            .pr-title {
                margin: 0;
                font-size: 16px;
                font-weight: bold;
            }

            ul {
                font-size: 14px;

                li {
                    transition: all 0.2 ease-in-out;

                    &.checked {
                        color: getColor(status, success);
                    }
                }
            }
        }

        .button-submit {
            height: 50px;
            margin: 40px 0 20px;
            text-transform: uppercase;
        }

        .button-register {
            width: 100%;
            border: none;
            cursor: pointer;
            font-weight: bold;
            height: 60px !important;
            text-transform: uppercase;
            border-radius: 0 0 4px 4px;
            color: getColor(text, white);
            transition: all 0.2s ease-in-out;
            background-color: getColor(secondary);

            &:hover {
                opacity: 0.6;
            }
        }
    }
}