@import "/src/common/styles/colors.scss";

.junction-send-button {
  display: flex;
  justify-content: flex-end;

  button {
    border-radius: 8px;
  }
  div {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    article {
      text-align: right;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 8px;
      color: getColor(status, success);
    }
  }
}

.link-input {
  width: 50% !important;
}
