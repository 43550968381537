@import "/src/common/styles/colors.scss";

.step-influencer {
    .card-influencer-table {
        margin-top: 32px;
        
        .container-influencer-table {
            margin: 0;
            padding: 0;
            border: none;
        }
    }

    .segment-influencer {
        display: flex;
        margin-top: 8px;
        position: relative;
        border-bottom: 1px solid getColor(background, medium);

        .segment-option-add-influencer {
            width: 33%;
            height: 40px;
            display: flex;
            cursor: pointer;
            padding-bottom: 2px;
            align-items: center;
            justify-content: center;
            color: getColor(text, base);
            transition: .2s all ease-in-out;

            &.active {
                font-weight: 500;
                color: getColor(primary);
            }

            &:hover {
                opacity: 0.6;
                transition: .2s all ease-in-out;
            }

            .icon {
                font-size: 20px;
                margin-right: 6px;
            }

            .text-add-import {
                font-size: 16px;
                font-weight: 700;
                letter-spacing: 0.44px;
            }
        }

        .segment-underline {
            left: 0;
            height: 2px;
            width: 33.3%;
            bottom: -2px;
            position: absolute;
            border-radius: 0 0 2px 2px;
            transition: .2s all ease-in-out;
            background-color: getColor(primary);
        }
    }

    .container-influencer-table {
        margin-top: 47px;
        padding-top: 50px;
        border-top: 1px solid getColor(background, medium);

        .junction-influencer-table {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            justify-content: space-between;

            .count-influencers {
                margin: 0;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: 0.15px;
                color: getColor(text, dark);
            }
        }
    }

    .text-empty-influencers {
        margin-top: 30px !important;
    }
}

.edit-influencer-modal {
    width: 70% !important;
    .junction-type-save {
        justify-content: flex-end;
    }
}