@import "/src/common/styles/colors.scss";

.card-payment-history {
    padding: 24px 12px;
    border-radius: 4px;
    background-color: getColor(background, lightest);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.06), 0 0 8px 0 rgba(0, 0, 0, 0.13) !important;

    .card-p-h-header {
        display: flex;
        flex-direction: column;

        .text-header {
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;
            margin-bottom: 20px;
        }
    }

    .text-p-h {
        font-size: 12px;
        margin: 16px 0 20px;
    }

    .table-p-h {
        width: 100%;
        border-spacing: 0 8px;
        border-collapse: separate;

        tr {
            width: 100%;
            height: 40px;
            padding: 10px;
            border-style: solid none;

            th,
            td {
                font-size: 10px;
                text-align: left;
                padding-left: 4px;
                font-weight: normal;
                border-top: solid 1px getColor(background, borderTable);
                border-bottom: solid 1px getColor(background, borderTable);

                &:first-child {
                    border-left-style: solid;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                    border-left: solid 1px getColor(background, borderTable);
                }

                &:last-child {
                    padding-right: 4px;
                    border-right-style: solid;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-right: solid 1px getColor(background, borderTable);
                }
            }

            td {
                height: 40px;
                background-color: getColor(background, light);
            }

            th {
                font-weight: 600;
                color: getColor(text, dark) !important;
                background-color: getColor(background, titleTable);
            }

            .icon {
                cursor: pointer;
                transition: all 0.2s ease-in-out;

                &:hover {
                    opacity: .6;
                }

                &.icon-trash {
                    font-size: 16px;
                }
            }
        }
    }

    .empty-data {
        width: 100%;
        padding: 20px;
        font-size: 20px;
        font-weight: 600;
        border-radius: 4px;
        text-align: center;
        text-transform: uppercase;
        border: solid 1px getColor(background, borderTable);
    }

}

.card.card-create-profile .card-content {
    display: flex;

    .form-title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 24px;
        color: getColor(text, dark);
    }
}

@media screen and (min-width: 768px) {
    .card-payment-history {
        padding: 24px;

        .card-p-h-header {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .text-header {
                margin: 0;
                font-size: 18px;
            }

            .rounded-input {
                height: 40px !important;
            }
        }

        .text-p-h {
            font-size: 14px;
            margin: 30px 0 20px;
        }

        .table-p-h {
            width: 100%;

            tr {
                width: 100%;
                height: 40px;
                padding: 10px;
                border-style: solid none;

                th,
                td {
                    font-size: 14px;
                    padding-left: 14px;

                    &:last-child {
                        padding-right: 14px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .card-payment-history {
        padding: 24px 40px;

        .card-p-h-header {
            .text-header {
                font-size: 20px;
            }
        }

        .text-p-h {
            font-size: 16px;
            margin: 12px 0 24px;
        }

        .table-p-h {
            tr {

                th,
                td {
                    font-size: 16px;
                }
            }
        }
    }
}