@import "/src/common/styles/colors.scss";

.junction-influencers {
  margin-top: 28px;
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  grid-gap: 24px;

  .link-influencer {
    margin-bottom: 25px;
    border: 1px solid #e7e6e6;
    padding: 16px;
    border-radius: 8px;

    .influencer-profile {
      margin-bottom: 17px;

      .influencer-name {
        font-size: 16px;
        line-height: 28px;
        padding-left: 10px;
        letter-spacing: 0.44px;
        text-transform: capitalize;
      }
    }

    .link-inputs {
      display: grid;
      grid-gap: 20px;
      align-items: flex-end;

      .input-junction {
        display: block;

        .label-input {
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.44px;
        }
      }

      .ant-btn {
        color: getColor(status, success) !important;
        border: 2px solid getColor(status, success) !important;
        background-color: getColor(text, white) !important;
        width: 100%;
        height: 44px;
        border-radius: 8px;

        .send-email-icon {
          margin-left: 10px;
        }
      }
    }
  }
}
