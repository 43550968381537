@import "/src/common/styles/colors.scss";

.ant-radio-checked .ant-radio-inner {
    border-color: getColor(primary) !important;
}

.ant-radio-checked .ant-radio-inner:after {
    background-color: getColor(primary) !important;
}

.ant-radio:hover .ant-radio-inner {
    border-color: getColor(primary) !important;
}

.ant-radio-checked::after {
    border: 1px solid getColor(primary) !important;
}

.ant-radio-checked .ant-radio-inner:focus {
    border-color: getColor(primary) !important;
}


.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
    border-color: getColor(primary) !important;
}

.junction-radios {
    width: 100%;

    &.radio-normal {
        .ant-radio-group {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            .option {
                .ant-radio-wrapper {
                    display: flex;
                    align-items: center;

                    .ant-radio {
                        margin-bottom: 5px;
                    }

                    span {
                        line-height: normal;
                        &.ant-radio {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }
    }

    &.radio-card {
        .ant-radio-group {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .option {
                width: 49%;

                .ant-radio-wrapper {
                    width: 100%;
                    height: 91px;
                    display: flex;
                    font-size: 16px;
                    padding: 0 26px;
                    line-height: 28px;
                    border-radius: 4px;
                    align-items: center;
                    letter-spacing: 0.44px;
                    box-sizing: border-box;
                    color: getColor(text, dark);
                    grid-template-columns: 1fr 12fr;
                    border: 1px solid getColor(background, medium);
                    background-color: getColor(background, light);

                    .option-value {
                        margin: 0;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    span:nth-child(1) {
                        top: -1px;
                        width: 16px;
                        height: 16px;
                    }

                    span:nth-child(2) {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .icon {
                            font-size: 32px;
                        }

                        .option-value {
                            width: 100%;
                        }
                    }
                }
            }

        }

        .labels {
            width: 100%;
            display: flex;
            margin-top: 8px;
            align-items: flex-start;
            justify-content: space-between;

            .option-text {
                width: 49%;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.22px;
                color: getColor(text, dark);
            }
        }
    }
}