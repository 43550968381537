

 .font-icon{
    display: block;
    color: white;
 }

 .tiktok-icon {
     width: 100%;
 }

 .icon-background {
    border-radius: 50%;
    margin-right: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

   .social-network-icon {
      padding: 22.73%;
   }
 }