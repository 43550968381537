@import "/src/common/styles/colors.scss";

.modal-plan-cancel {
    top: 0 !important;
    border-radius: 4px;
    width: 90% !important;
    max-width: 587px !important;

    .ant-modal-content {
        .ant-modal-header {
            border: none;
            padding: 20px 20px 0;

            .ant-modal-title {
                font-size: 16px;
                font-weight: 500;
                text-transform: uppercase;
            }
        }

        .ant-modal-body {
            .text {
                font-size: 14px;
                margin-bottom: 26px;
            }

            .checkbox-container {
                margin-bottom: 20px;

                .ant-checkbox-wrapper .checkbox-text {
                    font-size: 12px;
                }
            }

            .footer-plan-canel {
                width: 55%;
                max-width: 140px;
                padding-top: 20px;
                margin: 0 0 0 auto;

                .ant-btn {
                    .text-button {
                        font-size: 15px;
                        color: getColor(text, white);
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .modal-plan-cancel {
        .ant-modal-content {
            .ant-modal-header {
                padding: 32px 32px 0;

                .ant-modal-title {
                    font-size: 18px;
                }
            }

            .ant-modal-body {
                padding: 32px;
                .text {
                    font-size: 16px;
                    font-weight: 400;
                }

                .checkbox-container {
                    .ant-checkbox-wrapper .checkbox-text {
                        font-size: 14px;
                        font-weight: 400;
                    }
                }

                .footer-plan-canel {
                    .ant-btn {
                        .text-button {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .modal-plan-cancel {
        .ant-modal-content {
            .ant-modal-header {
                .ant-modal-title {
                    font-size: 20px;
                }
            }

            .ant-modal-body {
                .footer-plan-canel {
                    max-width: 167px;
                    padding-top: 48px;
                }
            }
        }
    }
}