@import "/src/common/styles/colors.scss";

.cc-card-footer {
    width: 100%;
    height: 50px;
    display: flex;
    padding: 0 24px;
    align-items: center;
    border-radius: 0 0 4px 4px;
    justify-content: space-between;
    background-color: getColor(background, light);

    &.end {
        justify-content: flex-end;
    }

    .button-step {
        padding: 0;
        border: none;
        display: flex;
        cursor: pointer;
        font-size: 16px;
        box-shadow: none;
        line-height: 28px;
        align-items: center;
        letter-spacing: 0.44px;
        background-color: transparent;
        .icon-back {
            margin-right: 5px;
        }
        .icon-next {
            margin-left: 5px;
        }

        .anticon-loading {
            padding: 0;
        }

        &:after {
            content: none;
        }

        &:focus {
            color: getColor(text, dark) !important;
            
            .icon {
                color: getColor(text, dark) !important;
            }
        }
        
        &:hover {
            color: getColor(primary) !important;
            
            .icon {
                color: getColor(primary) !important;
            }
        }

        &.button-next {
            display: flex;
            flex-direction: row-reverse;
        }
    }
}