@import "/src/common/styles/colors.scss";

.menu {
    top: 0;
    left: 0;
    width: 64px;
    height: 100vh;
    position: fixed;
    background-color: getColor(background, menu);
}

.container-page {
    width: 100%;
    padding: 40px;
}

.hypo-lake {
    opacity: 1;
    cursor: pointer;
    // position: relative;
    margin-top: 18px !important;
    transition: 0.2s all ease-in-out;


    &.opened {
        padding-left: 0 !important;

        .powered-text {
            opacity: 1;
            width: auto;
            right: 32px;
            font-size: 8px;
            position: absolute;
            transition: 0.3s all ease-in-out;
        }

        .logo {
            top: 19px;
            left: 89px;
            width: 26px;
        }

        .logo-label {

            // margin: 30px 0 10px;
            .o {
                width: 17px;
                transition: 0.2s all ease-in-out .3s;
            }

            .hyp {
                top: -4px;
                width: 56px;
                position: relative;
                transition: 0.2s all ease-in-out .3s;
            }

            .lake {
                top: -4px;
                width: 61px;
                position: relative;
                transition: 0.2s all ease-in-out .3s;
            }
        }
    }

    .powered-text {
        width: 0;
        height: 0;
        font-size: 0;
        margin-top: -4px;
        margin-right: -8px;
    }

    .logo {
        top: 10px;
        left: 13px;
        width: 38px;
        padding: 10px 2px;
        position: absolute;
        transition: 0.3s all ease-in-out;
        background-color: getColor(background, menu);
    }

    .logo-label {
        display: flex;
        font-size: 24px;
        margin: 40px 0 0 0;
        color: transparent;
        align-items: flex-start;
        justify-content: center;

        .hyp {
            width: 0;
            height: 30px;
            transition: 0.1s all ease-in-out .0s;
        }

        .o {
            width: 0;
            height: 24px;
            margin: -5px 5px 0;
            transition: 0.1s all ease-in-out .0s;
        }

        .lake {
            width: 0;
            height: 24px;
            transition: 0.1s all ease-in-out .0s;
        }
    }

    &:hover {
        opacity: .7;
        background-color: transparent !important;
    }
}

.divider {
    width: 80%;
    height: 1px;
    opacity: 0.5;
    margin: 20px auto;
    background-color: getColor(primary);

    &.divider-bottom {
        margin: 0 auto;
    }
}

.side-width {
    transition: 0.3s ease;
}

.ant-layout-sider {
    border-right: 1px solid getColor(background, subheader);
}

.ant-layout-sider,
.ant-layout-sider-children {
    transition: 0.3s ease;
    background-color: getColor(background, menu);

    &.ant-layout-sider-collapsed {
        width: 64px !important;
        max-width: 64px !important;
        min-width: 64px !important;
    }

    &.ant-layout-sider-collapsed {
        ul {
            li.ant-menu-item {
                &.ant-menu-item-selected {
                    background-color: getColor(primary, .3) !important;
                }

                &.tiktok-discovery {
                    border-radius: 50%;
                }
            }
        }
    }

    ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline,
    .ant-layout-sider-trigger {
        transition: 0.3s ease;

        li {
            .option-label {
                opacity: 1;
            }
        }
    }

    ul {
        background-color: getColor(background, menu) !important;

        .ant-menu-item-divider {
            width: 80%;
            height: 1px;
            opacity: 0.5;
            margin: 20px auto;
            background-color: getColor(primary);
        
        }
        
        &.ant-menu-vertical {
            .ant-menu-item {
                padding-left: 14px !important;

                .anticon {
                    font-size: 25px !important;
                }
            }
        }

        li.ant-menu-item.ant-menu-item-selected {
            opacity: 1 !important;
            background-color: getColor(primary, .3) !important;

            .ant-menu-title-content,
            i::before {
                color: getColor(primary);
            }
        }

        .ant-menu-item {
            height: 55px;
            display: flex;
            border-radius: 5px;
            align-items: center;
            left: 50% !important;
            width: 90% !important;
            margin: 0 0 8px !important;
            transform: translate(-50%);
            padding: 0 12px !important;
            color: getColor(text, dark) !important;
            background-color: getColor(background, menu) !important;

            .anticon {
                font-size: 21px !important;
            }

            .icon {
                margin: 0;
                width: 35px;
                display: flex;
                align-items: center;
                font-size: 24px !important;
                padding-left: 2px;

                &:before {
                    color: getColor(text, dark);
                }
            }

            .ant-menu-title-content {
                margin: 0;
            }

            .option-label {
                opacity: 0;
                font-size: 12px;
                font-weight: 500;
                line-height: 0 !important;
            }

            &:hover {
                transition: 0.2s ease-in all;
                background-color: getColor(primary, light, 0.1) !important;
            }
        }

    }
}

.container {
    width: 100%;
    bottom: 50px;
    display: flex;
    cursor: pointer;
    overflow: hidden;
    position: absolute;
    justify-content: center;

    svg {
        width: 70px;
        transition: 0.02s ease all;
    }

    &.active svg {
        width: 50px;
        font-size: 10px;
        margin-bottom: 10px;
        transform: rotate(90deg);
        transition: 0.02s ease all;
    }

    path {
        transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1),
            stroke-dasharray 500ms cubic-bezier(0.4, 0, 0.2, 1),
            stroke-dashoffset 500ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    path:nth-child(1) {
        transform-origin: 36% 40%;
    }

    path:nth-child(2) {
        stroke-dasharray: 29 299;
    }

    path:nth-child(3) {
        transform-origin: 35% 63%;
    }

    path:nth-child(4) {
        stroke-dasharray: 29 299;
    }

    path:nth-child(5) {
        transform-origin: 61% 52%;
    }

    path:nth-child(6) {
        transform-origin: 62% 52%;
    }

    &.active path:nth-child(1) {
        transform: translateX(9px) translateY(1px) rotate(45deg);
    }

    &.active path:nth-child(2) {
        stroke-dasharray: 225 299;
        stroke-dashoffset: -72px;
    }

    &.active path:nth-child(3) {
        transform: translateX(9px) translateY(1px) rotate(-45deg);
    }

    &.active path:nth-child(4) {
        stroke-dasharray: 225 299;
        stroke-dashoffset: -72px;
    }

    &.active path:nth-child(5) {
        transform: translateX(9px) translateY(1px) rotate(-45deg);
    }

    &.active path:nth-child(6) {
        transform: translateX(9px) translateY(1px) rotate(45deg);
    }
}