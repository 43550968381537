@import "/src/common/styles/colors.scss";

.campaign-details-page {
    .junction-header {
        color: getColor(text, dark);

        .subheader-left {
            .subheader-title {
                display: flex;
                font-size: 30px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 39px;
                overflow: hidden;
                align-items: center;
                text-transform: capitalize;

                .ant-btn {
                    margin-left: 12px;
                    &:hover {
                        background-color: transparent !important;
                    }

                    .icon-more {
                        padding: 8px;
                        border-radius: 50%;
                        font-weight: bold;
                        transition: 0.2s all ease-in-out;

                        &.icon-more-open {
                            transform: rotate(90deg);
                            background-color: getColor(background, grey);
                        }
                    }
                }

                .ant-spin {
                    height: auto;
                    margin-left: 17px;
                }
            }

            .text {
                font-size: 14px;
                line-height: 28px;
                color: #807f80;
                letter-spacing: 0.44px;
            }
        }

        .subheader-right {
            display: flex;
            align-items: center;

            .icon {
                margin-left: 8px;
            }
        }
    }

    .scroll-content {
        height: calc(100vh - 64px - 88px); // -header - subheader

        .card-loading {
            width: 100%;
            .container-loading {
                margin: 65px auto !important;
            }
        }

        .campaign-details-content {
            padding: 48px;
            margin: 0 auto;
            max-width: 1288px;
            overflow-x: hidden;
            box-sizing: content-box;

            .top-wrapper {
                display: flex;
                width: 100%;
                justify-content: space-between;

                .campaign-last-update {
                    display: flex;
                    align-items: flex-end;
                    margin-bottom: 12px;
                    color: #000;
                    text-align: center;
                    font-family: Roboto;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 28px; /* 175% */
                    letter-spacing: 0.44px;
                }
            }

            .chart-titles {
                display: flex;
                flex-direction: column;
            }

            .campaign-title-data {
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.15px;
                color: getColor(text, dark);
            }

            .campaign-subtitle-data {
                font-size: 14px;
                line-height: 19px;
                margin-bottom: 8px;
                margin: 8px 0 0 0 !important;
                color: getColor(text, dark);
            }

            .campaign-mentions {
                width: 100%;
                cursor: text;
                display: flex;
                flex-wrap: wrap;
                border-radius: 4px;
            }

            .performance-content {
                margin-bottom: 20px;
                justify-content: flex-end;
            }

            .campaign-not-started {
                display: flex;
                margin-top: 50px;
                align-items: center;
                justify-content: center;
                color: getColor(status, warning);

                .icon {
                    font-size: 50px;
                    font-weight: 600;
                    margin-right: 30px;
                }

                .text-not-started {
                    margin: 0;
                    font-size: 45px;
                    font-weight: 600;
                }
            }
        }

        .card {
            margin-bottom: 56px;

            .card-header {
                display: flex;
                align-items: center;
                margin-bottom: 24px;
                justify-content: space-between;

                .campaign-title-data {
                    margin: 0;
                }

                .campaign-subtitle-data {
                    margin: 8px 0 0 !important;
                }
            }

            // POSTS
            .card-content {
                .empty-value {
                    grid-column: span 4;
                }
                .posts {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &.feeds-posts,
                    &.stories-posts {
                        animation: fade 0.4s linear;

                        @keyframes fade {
                            0% {
                                opacity: 0;
                            }

                            100% {
                                opacity: 1;
                            }
                        }

                        .card-feed {
                            width: 24%;
                        }

                        .card-stories {
                            width: 24%;
                        }
                    }

                    &.start {
                        justify-content: start;
                        gap: 25px;
                    }
                }
            }

            // TABLE
            .table-card {
                .table-influencer {
                    animation: none !important;
                }
            }

            // CONTRATANTE
            .contractor-card {
                display: grid;
                grid-gap: 16px;
                color: #807f80;
                grid-template-columns: 5fr 2fr 2fr 2fr 2fr;

                .contractor-preview,
                .interaction-card,
                .empty-card {
                    width: 100%;
                    height: 80px;
                    display: flex;
                    padding: 0 8px;
                    font-size: 14px;
                    line-height: 24px;
                    border-radius: 4px;
                    align-items: center;
                    letter-spacing: 0.1px;
                    flex-direction: column;
                    justify-content: center;
                    background-color: getColor(background, light);
                    border: 1px solid getColor(background, medium);

                    &.empty-card {
                        background-color: getColor(background, light, 0.6);
                    }

                    &.contractor-preview {
                        display: flex;
                        padding: 0 24px;
                        align-items: center;
                        flex-direction: row;
                        grid-column: 1;
                        grid-row: 1 / 3;

                        .contractor-profile {
                            width: 65%;
                            display: flex;
                            align-items: center;

                            .name {
                                width: 80%;
                                font-size: 16px;
                                margin-left: 7px;
                                line-height: 28px;
                                overflow: hidden;
                                text-align: left;
                                white-space: nowrap;
                                letter-spacing: 0.44px;
                                text-overflow: ellipsis;
                                text-transform: capitalize;
                            }
                        }

                        .contractor-metrics {
                            width: 35%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .contractor-metric {
                                display: flex;
                                align-items: center;

                                .icon {
                                    font-size: 12px;
                                    margin-right: 6px;

                                    &.icon-airscore-leak {
                                        font-size: 20px;
                                        color: getColor(primary);
                                    }

                                    &.icon-posts {
                                        font-size: 20px;
                                        color: getColor(status, warning);
                                    }
                                }
                            }
                        }
                    }

                    &.interaction-card {
                        .tag-expired {
                            height: fit-content !important;
                        }
                        .interaction-junction {
                            display: flex;
                            align-items: center;

                            .icon {
                                font-size: 17px;
                                margin-right: 7px;

                                &.icon-air-share {
                                    cursor: pointer;
                                    transition: all 0.2s ease-in-out;

                                    &:hover {
                                        opacity: 0.6;
                                    }
                                }
                            }

                            .name {
                                color: getColor(text, dark);
                            }
                        }
                    }
                }
            }

            .card-footer {
                height: 39px;
                display: flex;
                padding: 0 24px;
                align-items: center;
                justify-content: flex-end;
                color: getColor(text, dark);
                transition: 0.2s all ease-in-out;
                background-color: getColor(background, lightest);
                border-top: 1px solid getColor(background, medium);

                div {
                    cursor: pointer;

                    &:hover {
                        color: getColor(primary);
                    }
                }

                span {
                    font-size: 16px;
                    line-height: 2px;
                    letter-spacing: 0.44px;
                }

                .icon {
                    font-size: 16px;
                    margin-left: 8px;
                }
            }
        }
    }
}

.tiktok-approve-paginator {
    .ant-pagination {
        font-size: 13.2px;
    }

    .ant-pagination-item-active {
        border: none;
        background: none;

        a {
            color: black;
        }
    }
    .ant-pagination-item:focus-visible a,
    .ant-pagination-item:hover a {
        color: getColor(primary, base);
    }

    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
        color: getColor(primary, dark);
    }

    .ant-pagination-prev:focus-visible .ant-pagination-item-link,
    .ant-pagination-next:focus-visible .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
        color: getColor(primary, base);
    }
}
