@import "/src/common/styles/colors.scss";

.checkbox-container {

    .ant-checkbox-wrapper {
        .ant-checkbox {
            .ant-checkbox-inner {
                width: 20px;
                height: 20px;
            }
        }

        .ant-checkbox-inner::after {
            top: 50%;
            left: 50%;
            width: 8px;
            height: 12px;
            border: 3px solid #fff;
            border-top: 0;
            border-left: 0;
            transform: translate(-55%, -70%) rotate(45deg);
        }

        .ant-checkbox-checked::after {
            border: 1px solid transparent !important;
        }

        &:hover .ant-checkbox-inner {
            border-color: getColor(primary) !important;
        }

        &:hover .ant-checkbox-inner,
        .ant-checkbox:hover .ant-checkbox-inner,
        .ant-checkbox-input:focus+.ant-checkbox-inner {
            border-color: getColor(primary) !important;
        }

        .ant-checkbox-inner {
            &:hover {

                border-color: getColor(primary) !important;
            }
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            border-color: getColor(primary) !important;
            background-color: getColor(primary);
        }

    }
    
    &.checkbox-card {
        width: 90%;
        .ant-checkbox-wrapper {
            height: 91px;
            display: flex;
            padding: 0 32px;
            font-size: 16px;
            line-height: 28px;
            border-radius: 4px;
            align-items: center;
            letter-spacing: 0.44px;
            color: getColor(text, dark);
            background-color: getColor(background, light);
            border: 1px solid getColor(background, medium);
        }
        .checkbox-description {
            font-size: 14px;
            line-height: 20px;
            margin: 8px 0 0 0;
            letter-spacing: 0.22px;
        }
    }
}
