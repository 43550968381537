.ant-popover-content {
    margin-right: 10px;
    width: 320px;

    h1 {
        color: #6a53bd;
        font-weight: lighter;
        line-height: 15px;
        margin-top: 10px;
    }

    h3 {
        font-weight: lighter;
    }

    .ant-popover-arrow {
        right: 66px !important;
        left: auto !important;
        transform: translateX(-50%);
    }

    .ant-list-item {
        .goal-item,
        .ant-list-item-meta-description {
            cursor: pointer !important;
        }

        :hover {
            color: #333 !important;

            .goal-empty-icon {
                stroke: #333 !important;
            }
            .goal-locked-icon {
                fill: #333 !important;
            }
        }
    }

    .custom-bullet {
        svg {
            width: 20px;
            fill: #6a53bd;
        }

        .goal-complete-icon {
            fill: #05d945;
        }
        .goal-empty-icon {
            stroke: #e6e6e6;
        }
        .goal-locked-icon {
            fill: #e6e6e6;
        }
    }
}

.float-button {
    width: 50px !important;
    height: 50px !important;
    padding: 6px;
    border-radius: 50%;
    background-color: #fff;
    border: 0;
    position: absolute;
    z-index: 999;
    right: 50px;
    bottom: 50px;
    -webkit-box-shadow: 5px 5px 15px 0px rgba(69, 69, 69, 0.45);
    -moz-box-shadow: 5px 5px 15px 0px rgba(69, 69, 69, 0.45);
    box-shadow: 5px 5px 15px 0px rgba(69, 69, 69, 0.45);
    cursor: pointer;
}

.float-button-badge {
    position: absolute;
    z-index: 999;
    right: 43px;
    bottom: 83px;
    border-radius: 50%;
    min-width: 24px;
    height: 24px;
    padding: 0px;
    color: #fff;
    text-align: center;
    justify-content: center;
    background-color: #6a53bd;
}
