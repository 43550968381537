@import "/src/common/styles/colors.scss";

@font-face {
  font-family: 'icomoon';
  src:  url('../assets/fonts/icomoon.eot?ep4tcb');
  src:  url('../assets/fonts/icomoon.eot?ep4tcb#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon.ttf?ep4tcb') format('truetype'),
    url('../assets/fonts/icomoon.woff?ep4tcb') format('woff'),
    url('../assets/fonts/icomoon.svg?ep4tcb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-copy:before {
  content: "\e9de";
}
.icon-growth-user:before {
  content: "\e901";
}
.icon-eye-essential:before {
  content: "\e902";
}
.icon-products:before {
  content: "\e903";
}
.icon-smiley:before {
  content: "\e904";
}
.icon-rocket:before {
  content: "\e905";
}
.icon-topic-business:before {
  content: "\e906";
}
.icon-topic-finance:before {
  content: "\e907";
}
.icon-add-influencer:before {
  content: "\e908";
}
.icon-airscore:before {
  content: "\e909";
}
.icon-alert:before {
  content: "\e90a";
}
.icon-analytics:before {
  content: "\e90b";
}
.icon-archieve:before {
  content: "\e90c";
}
.icon-back:before {
  content: "\e90d";
}
.icon-check:before {
  content: "\e90e";
}
.icon-close:before {
  content: "\e90f";
}
.icon-create-campaign:before {
  content: "\e910";
}
.icon-down:before {
  content: "\e911";
}
.icon-duplicate:before {
  content: "\e912";
}
.icon-edit:before {
  content: "\e913";
}
.icon-export:before {
  content: "\e914";
}
.icon-facebook:before {
  content: "\e915";
}
.icon-feed:before {
  content: "\e916";
}
.icon-filter:before {
  content: "\e917";
}
.icon-general-posts:before {
  content: "\e918";
}
.icon-help:before {
  content: "\e919";
}
.icon-import:before {
  content: "\e91a";
}
.icon-impressions:before {
  content: "\e91b";
}
.icon-instagram:before {
  content: "\e91c";
}
.icon-like-heart:before {
  content: "\e91d";
}
.icon-like-network:before {
  content: "\e91e";
}
.icon-link-external:before {
  content: "\e91f";
}
.icon-menu-burger:before {
  content: "\e920";
}
.icon-more:before {
  content: "\e921";
}
.icon-next:before {
  content: "\e922";
}
.icon-notification:before {
  content: "\e923";
}
.icon-pin:before {
  content: "\e924";
}
.icon-pinterest:before {
  content: "\e925";
}
.icon-planner-list:before {
  content: "\e926";
}
.icon-plus:before {
  content: "\e927";
}
.icon-reach:before {
  content: "\e928";
}
.icon-retweet:before {
  content: "\e929";
}
.icon-save:before {
  content: "\e92a";
}
.icon-search:before {
  content: "\e92b";
}
.icon-share-fb:before {
  content: "\e92c";
}
.icon-stories:before {
  content: "\e92d";
}
.icon-tooltip:before {
  content: "\e92e";
}
.icon-trash:before {
  content: "\e92f";
}
.icon-twitter:before {
  content: "\e930";
}
.icon-views:before {
  content: "\e931";
}
.icon-youtube:before {
  content: "\e932";
}
.icon-9:before {
  content: "\e933";
}
.icon-a-z:before {
  content: "\e934";
}
.icon-activism:before {
  content: "\e935";
}
.icon-add-influencer1:before {
  content: "\e936";
}
.icon-add-multiple:before {
  content: "\e937";
}
.icon-advance:before {
  content: "\e938";
}
.icon-air-share:before {
  content: "\e939";
}
.icon-air-symbol:before {
  content: "\e93a";
}
.icon-airscore-leak:before {
  content: "\e93b";
}
.icon-airscore1:before {
  content: "\e93c";
}
.icon-alert1:before {
  content: "\e93d";
}
.icon-analyse-copy:before {
  content: "\e93e";
}
.icon-analytics-graphic:before {
  content: "\e93f";
}
.icon-analytics1:before {
  content: "\e940";
}
.icon-anchor:before {
  content: "\e941";
}
.icon-archieve1:before {
  content: "\e942";
}
.icon-arrows:before {
  content: "\e943";
}
.icon-automotive:before {
  content: "\e944";
}
.icon-avatar-profile:before {
  content: "\e945";
}
.icon-aviation:before {
  content: "\e946";
}
.icon-beauty:before {
  content: "\e947";
}
.icon-beer:before {
  content: "\e948";
}
.icon-bio:before {
  content: "\e949";
}
.icon-calculator:before {
  content: "\e94a";
}
.icon-calendar:before {
  content: "\e94b";
}
.icon-card-list-outline:before {
  content: "\e94c";
}
.icon-card-outline:before {
  content: "\e94d";
}
.icon-card:before {
  content: "\e94e";
}
.icon-certificate:before {
  content: "\e94f";
}
.icon-check1:before {
  content: "\e950";
}
.icon-clock-copy:before {
  content: "\e951";
}
.icon-clock-outline:before {
  content: "\e952";
}
.icon-clock:before {
  content: "\e953";
}
.icon-close1:before {
  content: "\e954";
}
.icon-comment-post:before {
  content: "\e955";
}
.icon-comment:before {
  content: "\e956";
}
.icon-construction:before {
  content: "\e957";
}
.icon-create-campaign1:before {
  content: "\e958";
}
.icon-culture:before {
  content: "\e959";
}
.icon-dislike:before {
  content: "\e95a";
}
.icon-double-arrow-right:before {
  content: "\e95b";
}
.icon-duplicate1:before {
  content: "\e95c";
}
.icon-edit1:before {
  content: "\e95d";
}
.icon-education-copy:before {
  content: "\e95e";
}
.icon-environment:before {
  content: "\e95f";
}
.icon-error:before {
  content: "\e960";
}
.icon-etc-open:before {
  content: "\e961";
}
.icon-etc:before {
  content: "\e962";
}
.icon-expand:before {
  content: "\e963";
}
.icon-export1:before {
  content: "\e964";
}
.icon-Facebook1:before {
  content: "\e965";
}
.icon-fall:before {
  content: "\e966";
}
.icon-fashion:before {
  content: "\e967";
}
.icon-favourite-circle:before {
  content: "\e968";
}
.icon-favourite:before {
  content: "\e969";
}
.icon-feed1:before {
  content: "\e96a";
}
.icon-filter1:before {
  content: "\e96b";
}
.icon-form:before {
  content: "\e96c";
}
.icon-general-posts1:before {
  content: "\e96d";
}
.icon-graph-bar:before {
  content: "\e96e";
}
.icon-graph-line:before {
  content: "\e96f";
}
.icon-growth:before {
  content: "\e970";
}
.icon-hair:before {
  content: "\e971";
}
.icon-health:before {
  content: "\e972";
}
.icon-help1:before {
  content: "\e973";
}
.icon-hourglass:before {
  content: "\e974";
}
.icon-import1:before {
  content: "\e975";
}
.icon-impressions1:before {
  content: "\e976";
}
.icon-info:before {
  content: "\e977";
}
.icon-Instagram1:before {
  content: "\e978";
}
.icon-interaction-text:before {
  content: "\e979";
}
.icon-kids:before {
  content: "\e97a";
}
.icon-life-family:before {
  content: "\e97b";
}
.icon-lifestyle:before {
  content: "\e97c";
}
.icon-like-network1:before {
  content: "\e97d";
}
.icon-link-network:before {
  content: "\e97e";
}
.icon-login:before {
  content: "\e97f";
}
.icon-martial-art:before {
  content: "\e980";
}
.icon-menu-burger1:before {
  content: "\e981";
}
.icon-message:before {
  content: "\e982";
}
.icon-minus:before {
  content: "\e983";
}
.icon-more1:before {
  content: "\e984";
}
.icon-music:before {
  content: "\e985";
}
.icon-next1:before {
  content: "\e986";
}
.icon-notification1:before {
  content: "\e987";
}
.icon-nutrition:before {
  content: "\e988";
}
.icon-others:before {
  content: "\e989";
}
.icon-padlock:before {
  content: "\e98a";
}
.icon-pet:before {
  content: "\e98b";
}
.icon-photo-post:before {
  content: "\e98c";
}
.icon-pin1:before {
  content: "\e98d";
}
.icon-Pinterest1:before {
  content: "\e98e";
}
.icon-plus1:before {
  content: "\e98f";
}
.icon-posts:before {
  content: "\e990";
}
.icon-refresh:before {
  content: "\e991";
}
.icon-save1:before {
  content: "\e992";
}
.icon-search1:before {
  content: "\e993";
}
.icon-share-fb1:before {
  content: "\e994";
}
.icon-shared-economy:before {
  content: "\e995";
}
.icon-sports:before {
  content: "\e996";
}
.icon-stamp---airscore:before {
  content: "\e997";
}
.icon-stories1:before {
  content: "\e998";
}
.icon-technology:before {
  content: "\e999";
}
.icon-tooltip1:before {
  content: "\e99a";
}
.icon-topic-audiovisual:before {
  content: "\e99b";
}
.icon-topic-entertainment:before {
  content: "\e99c";
}
.icon-topics:before {
  content: "\e99d";
}
.icon-trash1:before {
  content: "\e99e";
}
.icon-travel:before {
  content: "\e99f";
}
.icon-twitter1:before {
  content: "\e9a0";
}
.icon-unshare:before {
  content: "\e9a1";
}
.icon-views1:before {
  content: "\e9a2";
}
.icon-youtube1:before {
  content: "\e9a3";
}
.icon-add-influencer2:before {
  content: "\e9a4";
}
.icon-advance1:before {
  content: "\e9a5";
}
.icon-air-share1:before {
  content: "\e9a6";
}
.icon-airscore2:before {
  content: "\e9a7";
}
.icon-alert2:before {
  content: "\e9a8";
}
.icon-analyse:before {
  content: "\e9a9";
}
.icon-archieve2:before {
  content: "\e9aa";
}
.icon-back1:before {
  content: "\e9ab";
}
.icon-badge-airscore:before {
  content: "\e9ac";
}
.icon-campaign:before {
  content: "\e9ad";
}
.icon-check2:before {
  content: "\e9ae";
}
.icon-close-circle:before {
  content: "\e9af";
}
.icon-close2:before {
  content: "\e9b0";
}
.icon-create-campaign2:before {
  content: "\e9b1";
}
.icon-duplicate2:before {
  content: "\e9b2";
}
.icon-ecommerce:before {
  content: "\e9b3";
}
.icon-edit2:before {
  content: "\e9b4";
}
.icon-error1:before {
  content: "\e9b5";
}
.icon-export2:before {
  content: "\e9b6";
}
.icon-Facebook2:before {
  content: "\e9b7";
}
.icon-feed2:before {
  content: "\e9b8";
}
.icon-filter2:before {
  content: "\e9b9";
}
.icon-general-posts2:before {
  content: "\e9ba";
}
.icon-hashtag:before {
  content: "\e9bb";
}
.icon-help2:before {
  content: "\e9bc";
}
.icon-import2:before {
  content: "\e9bd";
}
.icon-impressions2:before {
  content: "\e9be";
}
.icon-info1:before {
  content: "\e9bf";
}
.icon-Instagram2:before {
  content: "\e9c0";
}
.icon-like-heart1:before {
  content: "\e9c1";
}
.icon-like:before {
  content: "\e9c2";
}
.icon-more2:before {
  content: "\e9c3";
}
.icon-next2:before {
  content: "\e9c4";
}
.icon-people:before {
  content: "\e9c5";
}
.icon-pin2:before {
  content: "\e9c6";
}
.icon-plus2:before {
  content: "\e9c7";
}
.icon-posts1:before {
  content: "\e9c8";
}
.icon-reach1:before {
  content: "\e9c9";
}
.icon-return:before {
  content: "\e9ca";
}
.icon-retweet1:before {
  content: "\e9cb";
}
.icon-save2:before {
  content: "\e9cc";
}
.icon-search2:before {
  content: "\e9cd";
}
.icon-share-fb2:before {
  content: "\e9ce";
}
.icon-social-media:before {
  content: "\e9cf";
}
.icon-stories2:before {
  content: "\e9d0";
}
.icon-tooltip2:before {
  content: "\e9d1";
}
.icon-trash2:before {
  content: "\e9d2";
}
.icon-twitter2:before {
  content: "\e9d3";
}
.icon-views2:before {
  content: "\e9d4";
}
.icon-youtube2:before {
  content: "\e9d5";
}
.icon-anchor1:before {
  content: "\e9d6";
}
.icon-overview:before {
  content: "\e9d7";
}
.icon-eye:before {
  content: "\e9d8";
}
.icon-eye-blocked:before {
  content: "\e9d9";
}
.icon-likes:before {
  content: "\e9da";
}
.icon-play:before {
  content: "\e9db";
}
.icon-group:before {
  content: "\e9dc";
}
.icon-tiktok:before {
  content: "\e9dd";
}
.icon-checkmark:before {
  content: "\ea10";
}

.icon-facebook-circle {
  padding: 4px;
  border-radius: 50%;
  color: getColor(text, white) !important;
  background-color: getColor(socials, facebook) !important;
  &:before {
    content: "\e965";
  }  
}
.icon-pinterest-circle {
  padding: 4px;
  border-radius: 50%;
  color: getColor(text, white) !important;
  background-color: getColor(socials, pinterest) !important;
  &:before {
    content: "\e98e";
  }  
}
.icon-instagram-circle {
  padding: 4px;
  border-radius: 50%;
  color: getColor(text, white) !important;
  background-color: getColor(socials, instagram) !important;
  &:before {
    content: "\e91c";
  }
}
.icon-twitter-circle {
  padding: 4px;
  border-radius: 50%;
  color: getColor(text, white) !important;
  background-color: getColor(socials, twitter) !important;
  &:before {
    content: "\e930";
  }
}
.icon-tiktok-circle {
  padding: 4px;
  border-radius: 50%;
  color: getColor(text, white) !important;
  background-color: getColor(socials, tiktok) !important;
  &:before {
    content: "\e9dd";
  }  
}
.icon-youtube-circle {
  padding: 4px;
  border-radius: 50%;
  color: getColor(text, white) !important;
  background-color: getColor(socials, youtube) !important;
  &:before {
    content: "\e932";
  }  
}