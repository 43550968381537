@import "/src/common/styles/colors.scss";

.table-influencer {
  width: 100%;
  border-spacing: 0 7px;
  border-collapse: separate;

  thead {
    margin-bottom: 7px;

    tr {
      height: 40px;
      position: relative;

      th {
        height: 40px;
        padding: 1px;
        position: relative;

        .space {
          margin-right: 15px;
        }

        .icon-down,
        .icon-top {
          top: 48%;
          right: 5%;
          display: flex;
          position: absolute;
        }
      }

      th {
        margin: 1px;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        text-align: start;
        text-transform: uppercase;
        color: getColor(text, dark) !important;
        background-color: getColor(background, medium);
        border-top: 1px solid getColor(background, lightGrey) !important;
        border-bottom: 1px solid getColor(background, lightGrey) !important;

        .name-column {
          text-decoration: underline;
        }

        .th-header-container {
          width: 100%;
          text-align: start;
        }

        .icon-down {
          font-size: 12px;
          margin-left: 8px;
          text-decoration: none;
          transition: 0.2s all ease-in-out;

          &.bigger {
            transform: rotate(180deg) translateY(5px);
          }

          &.smaller {
            transform: rotate(0deg) translateY(-5px);
          }
        }

        &:first-child {
          text-align: center;
          padding-left: 26px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          border-left: 1px solid getColor(background, lightGrey) !important;
        }

        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          border-right: 1px solid getColor(background, lightGrey) !important;
        }

        &.default {
          cursor: default;
        }
      }

      .th-airscore {
        font-size: 0;

        .name-column {
          text-decoration: none;

          .icon {
            font-size: 20px;
            margin-bottom: 0;
            color: getColor(primary);
          }
        }

        .icon-down {
          position: absolute;
        }
      }
    }
  }

  tbody {
    tr {
      margin-bottom: 7px;

      th {
        height: 40px;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0;
        text-align: start;
        font-weight: normal;
        color: getColor(text, dark);
        background-color: getColor(background, light);
        border-top: 1px solid getColor(background, lightGrey) !important;
        border-bottom: 1px solid getColor(background, lightGrey) !important;

        &:first-child {
          text-align: left;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          border-left: 1px solid getColor(background, lightGrey) !important;
        }

        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          border-right: 1px solid getColor(background, lightGrey) !important;
        }

        &.bigger {
          color: getColor(status, success);

          &.minor {
            color: getColor(text, dark);
          }
        }

        &.minor {
          color: getColor(status, error);
        }
      }

      .th-position {
        padding-left: 20px;
      }

      .th-network {
        text-align: start;
        .tiktok-icon-circle {
          width: 22px;
          height: 22px;
          padding: 4px;
          border-radius: 50%;
          background-color: getColor(socials, tiktok);
        }
      }

      .th-profile {
        text-align: left;
        padding-left: 26px;
        text-transform: capitalize;

        .avatar {
          margin-right: 5px;
        }
      }

      .th-followers {
        .icon {
          margin-right: 5px;
        }
      }

      .th-trash {
        padding-right: 13px;

        .icon {
          font-size: 16px;
          cursor: pointer;
        }
      }

      .th-connect-status {
        display: flex;
        cursor: default;
        align-items: center;
        justify-content: flex-start;

        &.in-airscore {
          cursor: pointer;

          .icon {
            transition: 0.2s all ease-in-out;

            &:hover {
              opacity: 0.6;
            }
          }
        }

        .icon {
          font-size: 20px;
          margin-bottom: 4px;

          &.icon-stamp---airscore {
            margin-bottom: 0;
            color: getColor(secondary);
          }
        }

        .span-share {
          font-size: 16px;
          line-height: 28px;
          padding-left: 4px;
          letter-spacing: 0.44px;
          color: getColor(text, dark);
        }

        .logo {
          width: 16px;
        }
      }

      .th-tracking {
        div {
          display: flex;
          flex-direction: row;

          align-items: center;

          article {
            color: #4a4a4a7f;
          }

          .input-container {
            max-width: 220px;
            height: 32px;
            margin-right: 9px;

            .input-junction {
              .input.input-full {
                border: 1px solid #d6d5d5 !important;
                width: 100%;
                height: 32px;
                border-radius: 4px;
                color: #4a4a4a7f !important;
              }
            }
          }

          .ant-btn {
            height: 32px;
            padding: 0 6px;
            margin-right: 8px;
            border-radius: 8px;
            background-color: transparent !important;
            border: 2px solid getColor(status, success);
            color: getColor(status, success) !important;
          }

          .icon-copy {
            padding: 4px;
            font-size: 24px;
            cursor: pointer;
            transition: 0.2s all ease-in-out;
            color: getColor(primary) !important;
            &:hover {
              opacity: 0.6;
            }
          }
        }
      }

      .th-actions {
        .icon {
          margin: 0 2px;
          padding: 0 2px;
          font-size: 16px;
          cursor: pointer;
          transition: 0.2s all ease-in-out;
          &:hover {
            opacity: 0.6;
          }
        }
      }

      .th-campaign-invitation-status {
        .label-campaign-invitation-status::before {
          content: "\2022";
          font-weight: bold;
          vertical-align: middle;
          display: inline-block;
          width: 0.5em;
          font-size: 30px;
          padding-bottom: 2px;
          text-transform: capitalize;
        }

        .label-campaign-invitation-status-active::before {
          color: #3bb481 !important;
        }
        .label-campaign-invitation-status-pending::before {
          color: #f9d145 !important;
        }
        .label-campaign-invitation-status-declined::before {
          color: #b91227 !important;
        }
      }
    }

    .empty-table {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &.table-influencer-details {
    animation: openedLeft 0.2s;

    @keyframes openedLeft {
      from {
        transform: translate(-50%);
      }

      to {
        transform: translate(0);
      }
    }

    thead .table-c-titles {
      .th-name,
      .th-cpi,
      .th-interactionsTotal,
      .th-performance {
        text-align: left;
        padding-left: 10px;
      }

      .th-name {
        padding-left: 26px;
      }
    }

    tbody {
      .th-name,
      .th-cpi,
      .th-interactions,
      .th-performance {
        text-align: left;
        padding-left: 10px;
      }

      .th-name {
        padding-left: 26px;
      }
    }
  }

  &.table-influencer-ec {
    animation: opened 0.2s;

    @keyframes opened {
      from {
        transform: translate(50%);
      }

      to {
        transform: translate(0);
      }
    }
  }
}
