@import "/src/common/styles/colors.scss";

.card-integration-profile {
    padding: 16px;
    display: flex;
    min-width: 201px;
    border-radius: 4px;
    align-items: center;
    background-color: #F6F7F7;
    border: 1px solid #E5E5E5;
    .data-profile {
        display: flex;
        padding-left: 8px;
        flex-direction: column;
        .profile-name {
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.44px;
            color: getColor(text, dark);
        }
    }
}