@import "/src/common/styles/colors.scss";

.input-container-password {
    position: relative;
    .input-label {
        padding-right: 50px;
    }
    .icon, .icon-password {
        top: 50%;
        right: 0;
        cursor: pointer;
        font-size: 20px;
        padding: 8px 12px;
        position: absolute;
        color: getColor(text, dark);
        transform: translateY(-50%);
        transition: alls 0.2s ease-in-out;
    }
}
