@import "/src/common/styles/colors.scss";

.simple-pagination {
    display: flex;
    justify-content: flex-end;

    .ant-pagination-next,
    .ant-pagination-prev {
        &.ant-pagination-disabled:hover {
            button {
                color: rgba(0, 0, 0, .25) !important;
            }
        }

        button {
            border: none;
            border-radius: 50%;
            background-color: transparent;

            &:hover {
                color: getColor(primary) !important;
            }
        }
    }

    .ant-pagination-item {
        border: none;
        border-radius: 50%;
        background-color: transparent;

        &:hover {
            a {
                color: getColor(primary) !important;
            }
        }

        &.ant-pagination-item-active {
            background-color: getColor(primary) !important;

            a {
                color: #FFF !important;
            }
        }
    }
}