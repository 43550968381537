.hypotextarea {
    border: 0.5px solid #c3c3c3;
    border-radius: 3px;
    box-shadow: none;
}

.hypotextarea:focus {
    box-shadow: none;
    border: 0.5px solid #c3c3c3;
}
.hypotextarea:hover {
    border: 0.5px solid #c3c3c3;
}
