@import "/src/common/styles/colors.scss";

.tiktoker-details {
    display: flex;
    flex-direction: column;

    .form-filters {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 24px;
        margin-bottom: 24px;
        color: getColor(primary);
        border-bottom: 1px solid getColor(text, medium, .1);

        .item-filter-text {
            display: flex;
            padding: 4px 8px;
            border-radius: 8px;
            margin-right: 15px;
            align-items: center;
            background-color: getColor(primary, .23);

            .icon {
                margin-left: 8px;
                cursor: pointer;
                transition: all 0.2s ease-in-out;

                &:hover {
                    opacity: .6;
                }
            }
        }
    }

    .influencer-interactions {
        display: flex;
        margin: 24px 0 32px;
        justify-content: space-between;

        .influencer {
            padding: 16px 22px;
            width: calc(30% - 38px);
            cursor: default !important;
            justify-content: space-between;

            .icon {
                right: 0;
                bottom: 1px;
                font-size: 15px;
                border-radius: 50%;
                position: absolute;
                color: getColor(primary);
                background-color: white;
            }

            .bio {
                margin-bottom: 0 !important;
            }

            .card-data {
                margin: 16px 0;

                p {
                    display: flex;
                    font-size: 10px;
                    font-weight: 400;
                    margin-bottom: 8px;
                    padding-bottom: 8px;
                    justify-content: space-between;
                    border-bottom: 1px solid getColor(text, medium, .2);

                    &:last-child {
                        margin-bottom: 0;
                        border-bottom: none;
                    }
                }
            }
        }

        .interactions {
            width: 70%;
            display: flex;
            flex-wrap: wrap;
            grid-gap: 82px 0;
            padding: 51px 16px;
            border-radius: 4px;
            border: 1px solid #D6D5D5;
            justify-content: space-between;


            .interaction {
                width: 30%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-between;

                .text {
                    display: flex;
                    margin-bottom: 8px;
                    align-items: center;
                    text-transform: capitalize;

                    .icon {
                        font-size: 20px;
                        margin-right: 10px;
                        color: getColor(primary);
                    }

                    .name {
                        font-size: 16px;
                        font-weight: 400;
                    }

                    .icon-tooltip {
                        margin-left: 8px;
                        font-size: 12px;
                        color: getColor(text, dark);

                        &::before {
                            margin-bottom: -10px;
                        }
                    }
                }


                .value {
                    font-size: 24px;
                    font-weight: 700;
                    color: getColor(text, medium);
                }
            }
        }
    }

    .all-posts {
        .title-posts {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            margin-bottom: 4px;
        }

        .text-posts {
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            margin-bottom: 12px;
        }

        .influencer-posts {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            grid-gap: 24px 16px;
            justify-content: space-between;

            .card-post-video-simple {
                width: calc(20% - 16px);
            }
        }
    }
}