@import "/src/common/styles/colors.scss";
@import '~antd/dist/antd.css';

$selected-color: getColor(primary);

.button {
    height: 40px;
    border: none;
    display: flex;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease-in all;
    color: getColor(text, white) !important;

    &.not-text {
        .icon {
            margin: 0;
        }
    }
    .icon, .tiktok-icon {
        width: 18px;
        font-size: 18px;
        margin-right: 8px;
    }

    .text-button {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.15px;
    }

    .anticon-loading {
        padding-top: 2px;
        svg {
            font-size: 18px;
        }
    }

    &:hover {
        opacity: 0.6;
    }

    &.ant-btn[disabled] {
        opacity: 0.6;
    }
    
}

// normal
.pattern-primary {
    background-color: getColor(primary) !important;

    &:hover {
        background-color: getColor(primary);
    }
}

.pattern-secondary {
    background-color: getColor(secondary) !important;

    &:hover {
        background-color: getColor(secondary) !important;
    }
}

.pattern-danger {
    background-color: getColor(status, error) !important;

    &:hover {
        background-color: getColor(status, error);
    }
}

.pattern-success {
    background-color: getColor(status, success) !important;

    &:hover {
        background-color: getColor(status, success);
    }
}

.pattern-warning {
    background-color: getColor(status, warning) !important;

    &:hover {
        background-color: getColor(status, warning);
    }
}

.pattern-info {
    background-color: getColor(status, info) !important;

    &:hover {
        background-color: getColor(status, info);
    }
}

.pattern-grey {
    background-color: getColor(text, dark) !important;

    &:hover {
        background-color: getColor(text, dark);
    }
}

// outline
.outline-primary {
    color: getColor(primary) !important;
    border: 1px solid getColor(primary) !important;

    &:hover {
        color: getColor(primary);
        border: 1px solid getColor(primary);

    }
}

.outline-secondary {
    color: getColor(secondary) !important;
    border: 1px solid getColor(secondary) !important;

    &:hover {
        color: getColor(secondary);
        border: 1px solid getColor(secondary);

    }
}

.outline-danger {
    color: getColor(status, error) !important;
    border: 1px solid getColor(status, error) !important;

    &:hover {
        color: getColor(status, error);
        border: 1px solid getColor(status, error);

    }
}

.outline-success {
    color: getColor(status, success) !important;
    border: 1px solid getColor(status, success) !important;

    &:hover {
        color: getColor(status, success);
        border: 1px solid getColor(status, success);

    }
}

.outline-warning {
    color: getColor(status, warning) !important;
    border: 1px solid getColor(status, warning) !important;

    &:hover {
        color: getColor(status, warning);
        border: 1px solid getColor(status, warning);

    }
}

.outline-info {
    color: getColor(status, info) !important;
    border: 1px solid getColor(status, info) !important;

    &:hover {
        color: getColor(status, info);
        border: 1px solid getColor(status, info);

    }
}

.outline-grey {
    color: getColor(text, dark) !important;
    border: 1px solid getColor(text, dark) !important;

    &:hover {
        color: getColor(text, dark);
        border: 1px solid getColor(text, dark);

    }
}

.outlined-grey-button {
    color: getColor(text, dark);
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid getColor(text, dark);
    border-radius: 3px;
    height: 40px;
    background-color: white;
    padding: 8px 25px;

    &:hover {
        color: getColor(text, mediumGray);
        border: 1px solid getColor(text, mediumGray);

    }
}

.green-button {
    height: 40px;
    color: white !important;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    border-radius: 3px;
    padding: 8px 25px;
    transition: all .2s ease-in-out;
    border: 1px solid getColor(secondary, base) !important;
    background-color: getColor(secondary, base) !important;
    
    
    &:hover {
        opacity: 0.6;
    }
}

.black-Button {
    // height: 23px;
    border: 1px solid black;
    // font-family: Roboto;
    font-weight: 500;
    font-size: 11px;
    border-radius: 2px;
    padding: 5px 16px 5px 16px;
    background-color: none;
    cursor: pointer;
}

.reject-button {
    border: 1.6px solid #C77171;
    height: 33px;
    width: 118px;
    border-radius: 8px;
    background-color: white;
    color: #C77171;
    font-weight: 700;
    font-size: 13.2px;
    cursor: pointer;
}

.green-button {
    border: none;
    height: 33px;
    width: 118px;
    border-radius: 8px;
    background-color: #3BB481;
    color: white;
    font-weight: 700;
    font-size: 13.2px;
    cursor: pointer;
}

.outlined-green-button {
    border: 1.6506px solid #3BB481;
    height: 33px;
    width: 118px;
    border-radius: 8px;
    background-color: white;
    color: #3BB481;
    font-weight: 700;
    font-size: 13.2px;
    cursor: pointer;
}