@import "/src/common/styles/colors.scss";

.body-save {
    padding-bottom: 40px;
    .name-date {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .data-date {
            margin: 0;
            font-size: 16px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0.15px;
        }
    }

    .space-border {
        margin-top: 35px;
        padding-top: 35px;
        border-top: 1px solid #E7E6E6;
    }

    .subtitle {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 21px;
        letter-spacing: 0.15px;
        color: getColor(text, dark);
    }

    .type {
        display: flex;
        padding: 5px 16px;
        border-radius: 4px;
        align-items: center;
        justify-content: space-between;
        background-color: getColor(background, light);

        p {
            margin: 0;
        }
    }

    .mentions {
        margin-bottom: -16px;

        .tags-mentions {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .integration-nw {
        .profiles {
            display: flex;

            .profile {
                margin-right: 24px;

                .profile-name {
                    font-size: 16px;
                    line-height: 28px;
                    letter-spacing: 0.44px;
                    text-transform: capitalize;
                }
            }
        }
    }

    .integration-google {
        .account {
            display: flex;

            .card-integration-profile {
                height: 74px;
            }
        }

        .google-goals {
            margin-left: 24px;

            .goals {
                padding: 16px;
                // display: flex;
                min-height: 74px;
                min-width: 313px;
                border-radius: 4px;
                align-items: center;
                background-color: #F6F7F7;
                border: 1px solid #E5E5E5;

                .goal {
                    font-size: 16px;
                    line-height: 19px;
                    margin-bottom: 4px;
                    list-style-type: none;
                    letter-spacing: 0.44px;
                    color: getColor(text, dark);
                }
            }
        }
    }
}


.save-campaign {
    height: 72px !important;

    width: 100%;
    height: 50px;
    display: flex;
    padding: 0 24px;
    align-items: center;
    justify-content: space-between;
    background-color: getColor(background, light);

    &.end {
        justify-content: flex-end;
    }

    .button-step {
        padding: 0;
        border: none;
        display: flex;
        cursor: pointer;
        font-size: 16px;
        box-shadow: none;
        line-height: 28px;
        align-items: center;
        letter-spacing: 0.44px;
        background-color: transparent;

        &.button-primary {
            flex-direction: row-reverse;
        }

        &:after {
            content: none;
        }

        .anticon-loading {
            padding: 0;
        }

        .button-text {
            margin: 0 5px;
        }

        &:focus {
            color: getColor(text, dark) !important;

            .icon {
                color: getColor(text, dark) !important;
            }
        }

        &:hover {
            transition: all 0.2s ease-in-out;
            color: getColor(primary) !important;
        }
    }
}