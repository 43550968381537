@import "/src/common/styles/colors.scss";

.configuration-root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: getColor(text, gray2);

    .ant-tabs>.ant-tabs-nav {
        margin-bottom: 0;
    }

    .ant-tabs {
        height: 100% !important;

        .ant-tabs-nav-wrap {
            display: flex;
            padding: 0 48px;
            justify-content: center;
            background-color: getColor(text, white);
            border-bottom: 1px solid getColor(text, gray1);
            .ant-tabs-nav-list {
                width: 100%;
                max-width: 1288px;
                align-items: flex-end;
                height: 88px !important;
                
                .ant-tabs-tab {
                    font-size: 16px;
                    letter-spacing: 0.44px;
                    padding: 8px 0 !important;
                    color: getColor(text, dark);
    
                    &:hover {
                        color: getColor(text, violet) !important;
                    }
                }
            }


            .ant-tabs-ink-bar {
                background: getColor(text, violet) !important;
                height: 3px !important;
            }

            .ant-tabs-ink-bar .ant-tabs-ink-bar-animated {
                padding-left: 85px;
            }

            .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                font-weight: 600;
                color: getColor(text, violet) !important;
            }
        }
        .ant-tabs-content-holder {
            left: 50%;
            padding: 48px;
            max-width: 1288px;
            overflow-y: auto;
            position: relative;
            box-sizing: content-box;
            transform: translateX(-50%);
        }
    }

    .container-loading {
        width: 100%;
    }
}