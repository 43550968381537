@import "/src/common/styles/colors.scss";

.c-card-data {
    width: 100%;
    height: 80px;
    display: flex;
    overflow: hidden;
    border-radius: 8px;
    padding: 10px 14px;
    position: relative;
    text-align: center;
    flex-direction: column;
    color: getColor(text, dark);
    justify-content: space-between;
    background-color: getColor(background, light) !important;
    border: 1px solid #d2d2d2 !important;

    &.white {
        height: 110px;
        position: relative;
        align-items: flex-start;
        justify-content: center;
        background-color: getColor(background, lightest) !important;
        border: none !important;

        .c-card-data-title {
            // width: 100%;
            display: flex;
            // align-items: flex-start;
            justify-content: flex-start;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .c-card-data-content {
            width: 100%;
            margin-top: 10px;

            div {
                width: 100%;
                display: flex;
                justify-content: space-between;

                span {
                    width: auto;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .icon-tooltip {
            right: 14px;
            position: absolute;
        }
    }

    .c-card-data-title {
        display: flex;
        font-size: 14px;
        white-space: nowrap;
        align-items: center;
        letter-spacing: 0.44px;
        justify-content: center;
        text-overflow: ellipsis;
    }

    i~.title-card {
        margin-left: 8px;
        font-size: 16px;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
    }

    i {
        font-size: 14px;
        margin: 0 2px 0 3px;
    }

    .c-card-data-content {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 26px;
        white-space: nowrap;
    }
}