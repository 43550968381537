@import "/src/common/styles/colors.scss";

.container-select {
    position: relative;

    .select {
        border-radius: 4px;

        .ant-select-selector {
            font-size: 14px;
            align-items: center;
            justify-content: center;
            height: 40px !important;
            box-shadow: none !important;
            color: getColor(text) !important;
            border: 1px solid getColor(background, lightGrey) !important;
            background-color: getColor(background, lightest) !important;

            .ant-select-selection-item {
                color: getColor(text, black)
            }

            .ant-select-selection-placeholder {
                font-size: 14px;
                color: getColor(text, medium, .5);
            }
        }

        &.select-full .ant-select-selector {
            border: 1px solid getColor(background, medium) !important;
            background-color: getColor(background, light) !important;

            .ant-select-selection-search-input {
                height: 40px;
                display: flex;
                align-items: center;
            }

            .ant-select-selection-placeholder {
                font-size: 16px;
                color: getColor(text, medium);
            }
        }

        &.select-error .ant-select-selector {
            border: 1px solid getColor(status, error) !important;
        }
    }
}

.ant-select-item-option-content,
.ant-select-selection-item {
    .icon {
        font-size: 10px;
        margin-right: 5px;
    }

    .tiktok-icon {
        width: 19px;
        height: 18px;
        padding: 4px;
        margin-right: 8px;
        border-radius: 50%;
        background-color: getColor(socials, tiktok);
    }
}

.ant-select-item.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected {
    background-color: getColor(primary, light) !important;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
    background-color: getColor(primary, light) !important;
}

// item 
.ant-select-item.ant-select-item-option,
.ant-select-item.ant-select-item-option.ant-select-item-option-active {
    padding: 10px;
    font-size: 16px;
    color: getColor(text, medium);
    background-color: getColor(background, lightest) !important;

    &:hover {
        background-color: getColor(background, light) !important;
    }
}

.ant-select.select.select-outline.ant-select-single.ant-select-show-arrow {
    .ant-select-selector {
        .ant-select-selection-placeholder {
            font-size: 16px;
            color: getColor(text, dark);
        }
    }

    .ant-select-arrow {
        .anticon svg {
            fill: getColor(text, dark);
        }
    }
}

.ant-select-item,
.ant-select-selection-item {
    text-transform: capitalize;
}

.select-text-error {
    left: 0;
    top: 100%;
    position: absolute;
}

.ant-tree-select .ant-select-selector {
    min-height: 40px;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0;
    box-shadow: none !important;
    border: 1px solid getColor(background, medium) !important;
    background-color: getColor(background, light) !important;

    .ant-select-selection-placeholder {
        font-size: 16px;
        color: getColor(text, medium);
    }

    .ant-select-selection-overflow .ant-select-selection-overflow-item {
        .ant-select-selection-item {
            height: 32px;
            padding: 0 12px;
            font-size: 13px;
            border-radius: 4px;
            align-items: center;
            color: getColor(text, black);
            background: rgba(47, 47, 47, 0.1);

            .ant-select-selection-item-content {
                margin-right: 10px;
            }

            .ant-select-selection-item-remove {
                width: 14px;
                height: 14px;
                display: flex;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                border: 1px solid getColor(text, black);

                .anticon {
                    font-size: 8px;
                    line-height: 0;

                    svg {
                        fill: getColor(text, black);
                    }
                }
            }
        }
    }
}

.ant-tree-error .ant-select-selector {
    border: 1px solid getColor(status, error) !important;
}

// SELECT COLORS
.ant-select-tree-checkbox-checked::after,
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    border: 1px solid getColor(primary) !important;
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
    background-color: getColor(primary) !important;
}

.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus+.ant-select-tree-checkbox-inner {
    border: 1px solid getColor(primary) !important;
}