@import "/src/common/styles/colors.scss";

.chart-container {
    position: relative;
    .highcharts-legend-checkbox:checked {
        background-color: green;
    }
    .highcharts-legend-checkbox::checked {
        background-color: blue;
    }
    .highcharts-legend-checkbox[type=checkbox]:checked {
        background-color: red;
    }
    .highcharts-legend-checkbox {
        margin-top: 1px;
        transform: translateX(-100%);
    }
}