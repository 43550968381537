@import "/src/common/styles/colors.scss";

.inputs-contractors {
    display: flex;
    flex-direction: column;
}

.inputs-brands {
    .junction-input {
        width: 100%;
        margin-bottom: 24px;
        .input-container {
            width: 95% !important;
        }

        &.junction-name {
            width: 49%;
        }
    }

    .row-email-name {
        display: flex;
        
        .junction-input {
            width: 49% !important;
        }
    }

    .junction-brand-contact {
        width: 35%;
    }
}

.inputs-contractors {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .junction-input {
        width: 70%;
        .input-container {
            width: 100% !important;
        }
    }

    .ant-btn {
        margin-top: 20px;
    }
}

.container-table {
    margin-top: 31px;
    padding-top: 31px;
    border-top: 1px solid #E7E6E6;
}

.text-empty {
    font-size: 16px;
    color: getColor(status, error);
}